import React, { useState, useEffect } from 'react';
import { SearchBar } from 'components';
import { ReactComponent as SearchIcon } from 'images/search.svg';
import * as styles from './styles.module.scss';

const SearchBarInput = ({ blogPosts }) => {
  const [displaySearch, setDisplaySearch] = useState(false);

  const handleShowSearch = (event) => {
    if (event) event.preventDefault();
    // const headerElement = document.querySelector('.inventia-header');
    if (displaySearch) {
      // headerElement.style.marginBottom = 0;
      setDisplaySearch(false);
    } else {
      // headerElement.style.marginBottom = 0;
      setDisplaySearch(true);
    }
  };

  // use effect exit menu on sec keydown
  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);
  // C L O S E   O N   E S C
  const handleEscKey = (event) => {
    if (event.keyCode === 27) {
      setDisplaySearch(false);
    }
  };

  return (
    <>
      <section className={`${styles.searchContainer} ${displaySearch ? styles.activeContainer : ''}`}>
        <SearchBar active={displaySearch} closeSearch={handleShowSearch} blogPosts={blogPosts} />
      </section>
      <div className={styles.searchButtonContainer}>
        <button className={styles.searchButton} type="button" onClick={handleShowSearch}>
          <SearchIcon className={styles.searchIcon} />
          Search Resources
        </button>
      </div>
    </>
  );
};

export default SearchBarInput;
