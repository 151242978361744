import React from 'react';
import { Section, Image, Carousel, RichText } from 'components';
import { ReactComponent as QuotesIcon } from 'images/quotes.svg';
import { isEmptyHtml } from 'helpers';
import * as styles from './styles.module.scss';

const Item = ({ item }) => {
  const { quote, name, role, image, avatar, caption, card_background_color: backgroundColor } = item;
  return (
    <div className={`${styles.item} ${!image?.url ? styles.noImage : ''}`} style={{ backgroundColor }}>
      <div className={styles.itemContent}>
        <div className={styles.itemQuoteContainer}>
          <QuotesIcon className={styles.quotesIcon} />
          {quote?.html && <RichText html={quote?.html} className={styles.itemQuote} />}
          {/* <QuotesIcon className={`${styles.quotesIcon} ${styles.quotesIconEnd}`} /> */}
        </div>
        <div className={styles.itemAuthor}>
          {avatar?.url && (
            <div className={styles.itemAvatarContainer}>
              <Image className={styles.itemAvarar} image={avatar} objectFit="cover" />
            </div>
          )}
          <div className={styles.itemNameContainer}>
            {name?.text && <span className={styles.itemName}>{name?.text}</span>}
            {role?.text && <span className={styles.itemRole}>{role?.text}</span>}
          </div>
        </div>
      </div>
      {image?.url && (
        <div className={styles.itemImageContainer}>
          <Image className={styles.itemImage} image={image} objectFit="cover" />
          {caption?.html && <RichText html={caption?.html} className={styles.itemCaption} />}
        </div>
      )}
    </div>
  );
};

const TestimonialCarousel = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { content, title, background_color: backgroundColor, dark_text: darkText } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    cssEase: 'ease',
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 600,
    slidesToScroll: 1,
    draggable: true,
    centerMode: false,
    adaptiveHeight: false,
    slidesToShow: 1,
    useTransform: false,
  };

  const displayContentContainer = !isEmptyHtml(titleHtml) || !isEmptyHtml(contentHtml);

  return (
    <Section
      className={`${styles.section} ${!darkText ? styles.lightText : ''}`}
      containerClassName={styles.container}
      style={{ backgroundColor }}
      sliceName="TestimonialCarousel"
    >
      {displayContentContainer && (
        <div className={styles.contentContainer}>
          {titleHtml && <RichText html={titleHtml} className={styles.title} />}
          <RichText html={contentHtml} className={styles.content} />
        </div>
      )}
      <Carousel settings={settings} containerClassName={styles.carousel}>
        {items?.map((item) => (
          <Item item={item} key={item?.name} />
        ))}
      </Carousel>
    </Section>
  );
};

export default TestimonialCarousel;
