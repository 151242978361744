import React from 'react';
import { Link, Section } from 'components';
import * as styles from './styles.module.scss';

const CategoryButtons = ({ categories, categoryGridTheme }) => {

  const gridTheme = categoryGridTheme ? styles.darkMode : '';

  return (
    <Section className={`${gridTheme} ${styles.categoryButtonsSection}`} containerClassName={styles.container}>
      <span className={styles.title}>Resource Categories</span>
      <div className={styles.buttonsContainer}>
        {categories.map((category) => {
          const categoryText = category?.data?.category_name?.text;
          const categoryLink = category?.uid;
          return (
            <Link key={categoryText} to={`/resources/${categoryLink}/`} className={styles.button}>
              {categoryText}
            </Link>
          );
        })}
      </div>
    </Section>
  );
};

export default CategoryButtons;
