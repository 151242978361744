import React from 'react';
import * as styles from './styles.module.scss';

const CircleWithLine = ({ className, onClick = () => {}, pulse }) => (
  <div onClick={onClick} className={`${pulse ? styles.pulse : ''} ${className || ''} ${styles.indicator}`}>
    <span className={styles.line} />
    <span className={styles.circle} />
  </div>
);
export default CircleWithLine;
