import React from 'react';

const AnchorPoint = (props) => {
  const { data } = props;
  const { primary } = data;
  const { anchor_id: anchorIdRaw } = primary;

  const anchorId = anchorIdRaw ? anchorIdRaw.replace(/\s+/g, '-').toLowerCase() : '';

  return <div id={anchorId} />;
};

export default AnchorPoint;
