import React from 'react';
import { Section, Image, Link } from 'components';
import * as styles from './styles.module.scss';

const ProductsGrid = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, tagline } = primary;

  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;

  return (
    <Section containerClassName={styles.container} sliceName="ProductsGrid" className={styles.section}>
      <div className={styles.textContainer}>
        {taglineHtml && <div dangerouslySetInnerHTML={{ __html: taglineHtml }} className={styles.tagline} />}
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      </div>
      <div className={styles.cardGrid}>
        {items.map((item) => {
          const {
            feature_1: feature1,
            feature_2: feature2,
            feature_3: feature3,
            image,
            title: featureTitle,
            cta_link: ctaLink,
            cta_text: ctaText,
          } = item;
          return (
            <div className={styles.card} key={featureTitle.text}>
              {image?.url && <Image className={styles.image} image={image} />}
              {featureTitle?.html && (
                <div dangerouslySetInnerHTML={{ __html: featureTitle.html }} className={styles.featureTitle} />
              )}
              {feature1?.html && (
                <div dangerouslySetInnerHTML={{ __html: feature1.html }} className={styles.featureText} />
              )}
              {feature2?.html && (
                <div dangerouslySetInnerHTML={{ __html: feature2.html }} className={styles.featureText} />
              )}
              {feature3?.html && (
                <div dangerouslySetInnerHTML={{ __html: feature3.html }} className={styles.featureText} />
              )}
              {ctaLink?.url && (
                <div className={styles.ctaContainer}>
                  {ctaLink?.url && ctaText?.text && (
                    <Link className={`cta ${styles.button}`} to={ctaLink.url}>
                      {ctaText.text}
                    </Link>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default ProductsGrid;
