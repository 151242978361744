import React from 'react';
import { Section, Link } from 'components';
import * as styles from './styles.module.scss';

const FullWidthImageText = (props) => {
  const { data } = props;
  const { primary } = data;
  const { content, cta_link: ctaLink, cta_text: ctaText, image, button_type: buttonType } = primary;

  const contentHtml = content?.html;
  const hasImage = image?.url;
  const hasCta = ctaLink?.url && ctaText?.text;

  return (
    <Section
      containerClassName={styles.container}
      className={styles.section}
      sliceName="FullWidthImageText"
      bgImage={hasImage ? image : ''}
    >
      {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.title} />}
      {hasCta && (
        <Link
          className={`${buttonType ? 'button' : 'cta white'} ${buttonType ? styles.button : styles.cta}`}
          to={ctaLink.url}
        >
          {ctaText.text}
        </Link>
      )}
    </Section>
  );
};

export default FullWidthImageText;
