import React from 'react';
import './styles.scss';

const FormField = ({
  name,
  type,
  label,
  options,
  placeholder,
  defaultValue,
  autoFocus,
  handleChange,
  register,
  validation,
}) => {
  if (type === 'textarea') {
    return (
      <textarea
        {...register(name, validation)}
        name={name}
        id={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
      />
    );
  }
  if (type === 'select') {
    return (
      <>
        {label && <label htmlFor={name}>{label}</label>}
        <select name={name} {...register(name, validation)}>
          {options.map((option, index) => (
            <>
              {index === 0 && option === 'Please Select' ? (
                <option selected disabled hidden value="" defaultValue={defaultValue} key={option}>
                  {option}
                </option>
              ) : (
                <option value={option} defaultValue={defaultValue} key={option}>
                  {option}
                </option>
              )}
            </>
          ))}
        </select>
      </>
    );
  }

  if (type === 'radio' || type === 'checkbox') {
    return (
      <div className={`${type}-buttons`}>
        {options.map((option) => (
          <div key={option} className={`${type}-button`}>
            <input
              {...register(name, validation)}
              type={type}
              id={option}
              name={name}
              value={option}
              defaultValue={defaultValue}
              onChange={handleChange}
            />
            {type === 'checkbox' && (
              <svg viewBox="0 0 21 21">
                <polyline points="5 10.75 8.5 14.25 16 6" />
              </svg>
            )}
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </div>
    );
  }
  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        {...register(name, validation)}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
      />
    </>
  );
};

export default FormField;
