import React from 'react';
import { Image } from 'components';
import './styles.scss';

const isDev = process.env.NODE_ENV === 'development';

const Section = (props) => {
  const {
    className,
    containerClassName,
    children,
    sliceName,
    noContainer,
    bgImage,
    id,
    largeContainer,
    style,
    spacing,
    spacingOverride,
  } = props;

  const spacingClassMap = {
    None: 'no-spacing',
    Small: 'small-spacing',
    Medium: 'medium-spacing',
    Large: 'large-spacing',
  };

  const spacingOverrideClassMap = {
    None: '',
    'Remove Top Spacing': 'remove-top-spacing',
    'Remove Bottom Spacing': 'remove-bottom-spacing',
  };

  const spacingClass = spacingClassMap[spacing] || '';
  const spacingOverrideClass = spacingOverrideClassMap[spacingOverride] || '';

  return (
    <section
      id={id || ''}
      className={`section ${className || ''} ${spacingClass} ${spacingOverrideClass}`}
      data-slice={isDev ? sliceName : null}
      style={style || {}}
    >
      {bgImage && <Image image={bgImage} className="section-bg" />}
      {noContainer ? (
        children
      ) : (
        <div className={`container ${largeContainer ? 'small-container' : ''} ${containerClassName || ''}`}>
          {children}
        </div>
      )}
    </section>
  );
};

export default Section;
