import React from 'react';
import { PostCard, Link } from 'components';
import * as styles from './styles.module.scss';

// get cta text from settings

const BlogPostSidebar = ({ posts, className, location, data }) => {
  const {
    data: {
      resource_post_cta_content: ctaContent,
      resource_post_cta_link: ctaLink,
      resource_post_cta_text: ctaText,
      resource_post_cta_title: ctaTitle,
    },
  } = data;

  return (
    <div className={`${styles.sidebarSection} ${className || ''}`}>
      <div className={styles.container}>
        <span className={styles.heading}>Related resources</span>
        {posts.map((post, index) => {
          const postKeyId = `post_${index}`;

          return <PostCard post={post} key={postKeyId} sidebar />;
        })}
      </div>
      {ctaTitle?.text && <div dangerouslySetInnerHTML={{ __html: ctaTitle?.html }} className={styles.title} />}
      {ctaContent?.text && <div dangerouslySetInnerHTML={{ __html: ctaContent?.html }} className={styles.content} />}
      {ctaLink?.url && ctaText?.text && (
        <Link className={styles.link} to={ctaLink.url}>
          {ctaText.text}
        </Link>
      )}
    </div>
  );
};

export default BlogPostSidebar;
