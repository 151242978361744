import React from 'react';
import { PostGrid } from 'components';
import './styles.scss';

const SearchResults = ({ posts, query = '', darkMode }) => {
  if (!posts || posts.length < 1 || !query) {
    return (
      <div className="search-results">
        <div className="search-results-empty">
          <h1 className="search-results-empty-title">No results found for "{query}"</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="search-results">
      <PostGrid posts={posts} darkMode={darkMode} />
    </div>
  );
};

export default SearchResults;
