import React from 'react';
import { Section, Image, Carousel, Link } from 'components';
import * as styles from './styles.module.scss';

const LogosRow = (props) => {
  const { data } = props;
  const { items, primary } = data;
  const { title, content } = primary;

  const titleHtml = title?.html;
  const carouselVariant = items?.length > 5;

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    cssEase: 'ease',
    speed: 600,
    slidesToScroll: 1,
    draggable: true,
    centerMode: false,
    variableWidth: true,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const Container = ({ children }) => (
    <>
      {carouselVariant ? (
        <Carousel settings={settings} containerClassName={styles.carousel}>
          {children}
        </Carousel>
      ) : (
        <div className={styles.row}>{children}</div>
      )}
    </>
  );

  return (
    <Section
      containerClassName={styles.container}
      className={`${styles.section} ${carouselVariant ? styles.carouselVariant : ''}`}
      sliceName="LogosRow"
    >
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      {content?.text && <div dangerouslySetInnerHTML={{ __html: content?.html }} className={styles.content} />}
      <Container>
        {items.map((item) => {
          const { logo, link } = item;

          return (
            <>
              {link?.url ? (
                <Link to={link?.url} className={styles.imageContainer}>
                  <Image className={styles.image} image={logo} />
                </Link>
              ) : (
                <div className={styles.imageContainer} key={logo.url}>
                  <Image className={styles.image} image={logo} />
                </div>
              )}
            </>
          );
        })}
      </Container>
    </Section>
  );
};

export default LogosRow;
