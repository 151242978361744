import React from 'react';
import { Section, Link } from 'components';
import * as styles from './styles.module.scss';

const Contact = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { content, cta_link: ctaLink, cta_text: ctaText, title, background_image: bgImage } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasImage = bgImage?.url;
  const hasCta = ctaLink?.url && ctaText?.text;

  return (
    <Section
      containerClassName={styles.container}
      className={styles.section}
      sliceName="Contact"
      bgImage={hasImage ? bgImage : ''}
    >
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
      {hasCta && (
        <Link className={`button ${styles.button}`} to={ctaLink.url}>
          {ctaText.text}
        </Link>
      )}
      <span className={styles.companyName}>Inventia Life Science Pty Ltd</span>
      <div className={styles.grid}>
        {items.map((item) => {
          const { contact_details: details, location } = item;
          return (
            <div key={location?.text}>
              {location?.html && (
                <div dangerouslySetInnerHTML={{ __html: location?.html }} className={styles.location} />
              )}
              {details?.html && <div dangerouslySetInnerHTML={{ __html: details?.html }} className={styles.details} />}
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default Contact;
