import React from 'react';
import { Link } from 'components';

const Button = props => {
  const { to, title, className, children, theme = 'primary', outline } = props;
  return (
    <Link to={to} title={title || ''} className={`button ${theme} ${outline ? 'outline' : ''} ${className || ''}`}>
      {children}
    </Link>
  );
};

export default Button;
