import React from 'react';
import { Section, Link, Image, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const TwoColsTextImage = (props) => {
  const { data } = props;
  const { primary, id } = data;
  const {
    image,
    align_image_left: alignLeft,
    content,
    title,
    cta_text: ctaText,
    cta_link: ctaLink,
    spacing,
    spacing_override: spacingOverride,
  } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasImage = image?.url;
  const hasCta = ctaLink?.url && ctaText?.text;

  return (
    <Section
      containerClassName={styles.container}
      sliceName="TwoColsTextImage"
      className={`${styles.section} ${alignLeft ? styles.alignLeft : ''}`}
      spacing={spacing}
      spacingOverride={spacingOverride}
      id={id}
    >
      <div className={styles.grid}>
        <OnVisible className={styles.textContainer} visibleClassName={styles.textVisible}>
          {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
          {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
          {hasCta && (
            <Link to={ctaLink.url} className={`${styles.button} button primary`}>
              {ctaText.text}
            </Link>
          )}
        </OnVisible>
        <OnVisible className={styles.imageContainer} visibleClassName={styles.imageVisible}>
          {hasImage && <Image image={image} className={styles.image} />}
        </OnVisible>
      </div>
    </Section>
  );
};

export default TwoColsTextImage;
