import React from 'react';
import { Section, Link, CircleWithLine } from 'components';
import { ReactComponent as Logo } from 'images/logo.svg';
import * as styles from './styles.module.scss';

const Navigator = ({ links }) => {
  const parents = links?.filter((navLink) => !navLink?.parent?.url);

  return (
    <Section containerClassName={styles.container} className={styles.section}>
      <Logo className={styles.logo} />
      <div className={styles.navLinks}>
        {parents.map((link, index) => {
          const { link_text: text, link_url: to } = link;
          const sublinks = links.filter((subLink) => subLink?.parent?.url === to?.url);
          return (
            <div className={styles.navItemContainer}>
              <div key={text.text} className={styles.linkContainer}>
                <Link to={to.url} className={styles.navLink} activeClassName={styles.activeLink}>
                  <span className={styles.linkNumber}>{`0${index + 1}`}</span>
                  <span className={styles.linkText}>{text.text}</span>
                </Link>
                <CircleWithLine className={styles.indicator} />
              </div>
              {!!sublinks?.length && (
                <div className={styles.sublinksContainer}>
                  {sublinks.map((item) => {
                    const { link_text: linkText, link_url: childLink } = item;
                    return (
                      <Link to={childLink?.url} className={styles.sublink}>
                        <span className={styles.toggle} />
                        <span>{linkText?.text}</span>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default Navigator;
