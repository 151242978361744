import React, { useState } from 'react';
import { Section, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const ResourcesAccordion = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, content } = primary;
  const titleHtml = title?.html;

  const [activeIndex, setActiveIndex] = useState([]);

  const handleOnClick = (event, index) => {
    event.preventDefault();
    setActiveIndex((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((prevIndex) => prevIndex !== index);
      }
      return [...prevIndexes, index];
    });
  };

  const escapeDoubleQuotes = (str) => str.replace(/\\([\s\S])|(")/g, '\\$1$2');

  const schema = `
  <script type="application/ld+json">
    {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      ${items.map(
    (item) => `
        {
          "@type": "Question",
          "name": "${item.title?.text}",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "${escapeDoubleQuotes(item.content?.html)}"
          }
        }`
      )}]
    }
    </script>
  `;

  return (
    <Section
      sliceName="ResourcesAccordion"
      className={`${styles.faqAccordion} faq-accordion`}
      containerClassName={styles.accordionContainer}
    >
      <OnVisible className={styles.textContainer} visibleClassName={styles.visible}>
        {titleHtml && (
          <div
            className={styles.title}
            id={title?.text?.replace(/\s+/g, '-').toLowerCase()}
            dangerouslySetInnerHTML={{ __html: titleHtml }}
          />
        )}
        {content?.text && <div dangerouslySetInnerHTML={{ __html: content?.html }} className={styles.content} />}
      </OnVisible>

      <OnVisible className={styles.accordionItems} visibleClassName={styles.visible}>
        {items.map((item, index) => {
          const { content: answer, title: question } = item;
          const questionText = question?.text;
          const answerHtml = answer?.html;
          const isActive = activeIndex.includes(index);

          return (
            <div
              key={questionText}
              className={`${styles.accordionItem} ${isActive ? styles.active : styles.inactive}`}
              aria-hidden="true"
            >
              <div className={styles.titleContainer} onClick={(event) => handleOnClick(event, index)}>
                <div dangerouslySetInnerHTML={{ __html: question.html }} className={styles.question} />
                <div className={styles.accordionItemToggleContainer}>
                  <div className={styles.accordionItemToggle} />
                </div>
              </div>
              {answerHtml && <div className={styles.answer} dangerouslySetInnerHTML={{ __html: answerHtml }} />}
            </div>
          );
        })}
      </OnVisible>
      <div dangerouslySetInnerHTML={{ __html: schema }} />
    </Section>
  );
};

export default ResourcesAccordion;
