module.exports = [
  {
    prismicLocale: 'en-us',
    pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    title: 'Revolutionising 3D cell biology', // Default Site Title used for SEO & PWA
    description:
      'It is well established that 3D cell models better represent human tissues. They more accurately replicate biological processes, disease physiology and drug responses. The age of 2D cell culture is over.', // Default Site Decription used for SEO
    siteName: 'Inventia', // Sitename for Facebook
    siteLanguage: 'en', // Language Tag on <html> element
    banner: '/open-graph.png', // Default OpenGraph image
    ogLanguage: 'en_AU', // Facebook Language
    socialLinks: [
      'https://www.facebook.com/inventia.life.science/',
      'https://twitter.com/InventiaLifeSci',
      'https://www.linkedin.com/company/inventia-life-science/',
      'https://www.youtube.com/channel/UCBAr_UySphuEBWEmElHMqUw',
    ], // Array of social links (facebook, insta, etc)
    // JSONLD / Manifest
    icon: 'src/images/icon.png', // Used for manifest favicon, splash screen, and icon generation
    shortName: 'Inventia', // shortname for manifest. MUST be shorter than 12 characters
    author: 'Inventia', // Author for schemaORGJSONLD
    themeColor: '#EC0182', // PWA Icon background & address bar colour if installed on desktop
    backgroundColor: '#EC0182', // PWA colour shown before styles and content loads, should match the background-color CSS property in the site’s stylesheet

    twitter: '@InventiaLifeSci', // Twitter Username
  },
  {
    prismicLocale: 'ja-jp',
    pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    title: 'Revolutionising 3D cell biology', // Default Site Title used for SEO & PWA
    description:
      'It is well established that 3D cell models better represent human tissues. They more accurately replicate biological processes, disease physiology and drug responses. The age of 2D cell culture is over.', // Default Site Decription used for SEO
    siteName: 'Inventia', // Sitename for Facebook
    siteLanguage: 'ja', // Language Tag on <html> element
    banner: '/open-graph.png', // Default OpenGraph image
    ogLanguage: 'ja_JP', // Facebook Language
    socialLinks: [
      'https://www.facebook.com/inventia.life.science/',
      'https://twitter.com/InventiaLifeSci',
      'https://www.linkedin.com/company/inventia-life-science/',
      'https://www.youtube.com/channel/UCBAr_UySphuEBWEmElHMqUw',
    ], // Array of social links (facebook, insta, etc)
    // JSONLD / Manifest
    icon: 'src/images/icon.png', // Used for manifest favicon, splash screen, and icon generation
    shortName: 'Inventia', // shortname for manifest. MUST be shorter than 12 characters
    author: 'Inventia', // Author for schemaORGJSONLD
    themeColor: '#EC0182', // PWA Icon background & address bar colour if installed on desktop
    backgroundColor: '#EC0182', // PWA colour shown before styles and content loads, should match the background-color CSS property in the site’s stylesheet

    twitter: '@InventiaLifeSci', // Twitter Username
  },
  {
    prismicLocale: 'ko-kr',
    pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    title: 'Revolutionising 3D cell biology', // Default Site Title used for SEO & PWA
    description:
      'It is well established that 3D cell models better represent human tissues. They more accurately replicate biological processes, disease physiology and drug responses. The age of 2D cell culture is over.', // Default Site Decription used for SEO
    siteName: 'Inventia', // Sitename for Facebook
    siteLanguage: 'ko', // Language Tag on <html> element
    banner: '/open-graph.png', // Default OpenGraph image
    ogLanguage: 'ko_KR', // Facebook Language
    socialLinks: [
      'https://www.facebook.com/inventia.life.science/',
      'https://twitter.com/InventiaLifeSci',
      'https://www.linkedin.com/company/inventia-life-science/',
      'https://www.youtube.com/channel/UCBAr_UySphuEBWEmElHMqUw',
    ], // Array of social links (facebook, insta, etc)
    // JSONLD / Manifest
    icon: 'src/images/icon.png', // Used for manifest favicon, splash screen, and icon generation
    shortName: 'Inventia', // shortname for manifest. MUST be shorter than 12 characters
    author: 'Inventia', // Author for schemaORGJSONLD
    themeColor: '#EC0182', // PWA Icon background & address bar colour if installed on desktop
    backgroundColor: '#EC0182', // PWA colour shown before styles and content loads, should match the background-color CSS property in the site’s stylesheet

    twitter: '@InventiaLifeSci', // Twitter Username
  },
];
