import React from 'react';
import { Link } from 'components';
import allWebsites from '../../../config/website';
import * as styles from './styles.module.scss';

const Logo = () => {
  const localeWebsite = allWebsites.find((site) => site.prismicLocale === process.env.GATSBY_SITE_LANG);
  const { siteName } = localeWebsite;

  return (
    <Link className={styles.logo} to="/">
      {siteName}
    </Link>
  );
};

export default Logo;
