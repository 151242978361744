import React, { useState, useEffect } from 'react';
import { Section, Image, Modal } from 'components';
import * as styles from './styles.module.scss';

const HoverGrid = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, background } = primary;

  const titleHtml = title?.html;
  const backgrounds = {
    Black: styles.blackBg,
    White: styles.whiteBg,
    Grey: styles.greyBg,
  };

  // MODAL LOGIC
  const [showModal, setShowModal] = useState(false);
  const [bodyLocked, setBodyLock] = useState(false); // lock body while showModal = true
  const [modalIndex, setActiveModal] = useState(null);

  const openModal = (event, index) => {
    event.preventDefault();
    setShowModal(true);
    setActiveModal(index);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false);
    setActiveModal(false);
  };

  const lockBody = () => {
    // lock body while modal open
    document.body.classList.add('modal-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    // when modal closed, unlock body
    document.body.classList.remove('modal-open');
    setBodyLock(false);
  };
  // use effect when showmodal updated
  useEffect(() => {
    if (showModal && !bodyLocked) lockBody();
    if (!showModal && bodyLocked) unlockBody();
  }, [showModal]);

  const modalData = items[modalIndex];

  return (
    <Section
      className={`${backgrounds[background]} ${styles.section}`}
      containerClassName={styles.container}
      sliceName="HoverGrid"
    >
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      <div className={styles.grid}>
        {items.map((item, index) => {
          const { image, post_hover_text: hoverText, pre_hover_text: cardTitle } = item;
          return (
            <div className={styles.card} key={cardTitle.text}>
              {/* card bg image */}
              {image?.url && <Image image={image} />}
              <div
                className={styles.textContainer}
                onClick={(event) => openModal(event, index)}
                role="button"
                tabIndex={index}
              >
                {cardTitle?.html && (
                  <div dangerouslySetInnerHTML={{ __html: cardTitle.html }} className={styles.cardTitle} />
                )}
                {hoverText?.html && (
                  <div dangerouslySetInnerHTML={{ __html: hoverText.html }} className={styles.cardContent} />
                )}
                {/* Read more button */}
                <span className={styles.readMore}>Read more</span>
              </div>
              {/* READ MORE MODAL */}
              <Modal active={showModal} closeModal={closeModal} className={styles.modal} title="close">
                <div className={styles.modalContainer}>
                  <div className={styles.modalText}>
                    {modalData?.pre_hover_text?.html && (
                      <div
                        dangerouslySetInnerHTML={{ __html: modalData?.pre_hover_text?.html }}
                        className={styles.modalTitle}
                      />
                    )}
                    {modalData?.read_more_text?.html && (
                      <div
                        dangerouslySetInnerHTML={{ __html: modalData?.read_more_text?.html }}
                        className={styles.modalContent}
                      />
                    )}
                  </div>
                  <div className={styles.modalImgContainer}>
                    {(modalData?.modal_image || modalData?.image) && (
                      <Image
                        image={modalData?.modal_image?.url ? modalData.modal_image : modalData.image}
                        className={styles.modalImage}
                      />
                    )}
                  </div>
                </div>
              </Modal>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default HoverGrid;
