import React from 'react';
import { Section, RichText, Carousel, Link, Image } from 'components';
import * as styles from './styles.module.scss';

const Item = ({ item }) => {
  const { title, content, cta_link: ctaLink, cta_text: ctaText, image } = item;
  const hasCta = ctaText?.text && ctaLink?.url;
  return (
    <div className={styles.cardContainer}>
      {image?.url && <Image image={image} alt={title?.text} />}
      <div className={styles.cardContentContainer}>
        {title?.text && <RichText html={title.html} className={styles.cardTitle} h3 />}
        {content?.text && <RichText html={content?.html} className={styles.cardContent} />}
        {ctaLink?.url && (
          <Link className={styles.cardCta} to={ctaLink?.url}>
            {ctaText?.text || 'Learn More'}
          </Link>
        )}
      </div>
    </div>
  );
};

const CarouselSlice = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    content,
    title,
    link,
    link_text: linkTextObj,
    background_color: backgroundColor,
    dark_text: darkText,
  } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const linkUrl = link?.url;
  const linkText = linkTextObj?.text;

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    cssEase: 'ease',
    autoplay: true,
    speed: 600,
    slidesToScroll: 1,
    draggable: true,
    centerMode: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Section
      className={`${styles.section} ${!darkText ? styles.lightText : ''}`}
      style={{ backgroundColor }}
      containerClassName={styles.container}
      sliceName="Carousel"
    >
      <div className={styles.contentContainer}>
        {titleHtml && <RichText html={titleHtml} className={styles.title} />}
        <RichText html={contentHtml} className={styles.content} />
        {linkUrl && (
          <Link className={styles.link} to={linkUrl}>
            {linkText}
          </Link>
        )}
      </div>
      <Carousel settings={settings} containerClassName={styles.carousel}>
        {items?.map((item) => (
          <Item item={item} key={item?.title?.text} />
        ))}
      </Carousel>
    </Section>
  );
};

export default CarouselSlice;
