import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { Link, Hamburger } from 'components';
import { use100vh } from 'react-div-100vh';
import { ReactComponent as Logo } from 'images/logo.svg';
import * as styles from './styles.module.scss';

const Header = ({ theme }) => {
  const staticData = useStaticQuery(graphql`
    query settingsQuery {
      allPrismicSettings {
        nodes {
          lang
          _previewable
          data {
            navigation_links {
              link_text {
                html
                text
              }
              link_url {
                url
              }
              parent {
                url
              }
            }
          }
        }
      }
    }
  `);

  const langSettingsData = staticData.allPrismicSettings.nodes.find(
    (node) => node.lang === process.env.GATSBY_SITE_LANG
  );

  const { data: settingsData } = useMergePrismicPreviewData(langSettingsData);

  const {
    data: { navigation_links: navigationLinks },
  } = settingsData;

  const lightMode = theme === 'light' ? styles.lightTheme : '';

  const [showNav, setShowNav] = useState(false); // mobile menu
  const [showSublinks, setShowSublinks] = useState(false); // sublinks on mobile
  const [bodyLocked, setBodyLock] = useState(false); // lock body while show nav = true
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollPos, setScrollPos] = useState(null);
  const [upScroll, setUpScroll] = useState(0);

  const scrollListener = () => {
    const prevScrollPos = scrollPos;
    const newScrollPos = window.pageYOffset;
    if (newScrollPos < prevScrollPos) {
      setUpScroll(true);
    }
    if (newScrollPos > prevScrollPos) {
      setUpScroll(false);
    }
    setScrollPos(window.pageYOffset);
  };

  useEffect(() => {
    if (window.pageYOffset === 0) {
      setUpScroll(false);
    }
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [scrollPos]);

  /// H A M B U R G E R   L O G I C /////////////////////

  const toggleNav = (event) => {
    event.preventDefault();
    setShowSublinks(null);
    setShowNav(!showNav);
  };

  const handleEscKey = (event) => {
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      setShowSublinks(null);
      setShowNav(false);
    }
  };

  const lockBody = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('nav-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      unlockBody();
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  useEffect(() => {
    if (showNav && !bodyLocked) lockBody();
    if (!showNav && bodyLocked) unlockBody();
  }, [showNav]);

  // styles
  const hamburgerOpenClass = showNav ? styles.hamburgerOpen : '';
  const scrolledUp = upScroll ? styles.scrolledUp : styles.scrollTop;
  const height = use100vh();
  const classNames = `${scrolledUp} ${lightMode} ${styles.headerContainer} ${hamburgerOpenClass || ''}`;
  const parents = navigationLinks?.filter((navLink) => !navLink?.parent?.url);

  const handleClick = (event, index) => {
    event.preventDefault();
    if (showNav) {
      if (showSublinks === index) {
        setShowSublinks(null);
      } else {
        setShowSublinks(index);
      }
    }
  };

  const NavItem = ({ title, link, childLinks, index }) => (
    <div
      onClick={(event) => handleClick(event, index)}
      role="button"
      tabIndex={0}
      className={`${showSublinks === index ? styles.activeParent : ''} ${styles.parentContainer} ${
        childLinks?.length ? styles.parentHasSublinks : ''
      }`}
    >
      <div className={styles.parentLink}>
        <Link
          key={title.text}
          className={styles.navLink}
          to={link.url}
          activeClassName={styles.activeLink}
          style={{ transitionDelay: `${index * 0.1}s` }}
        >
          <span className={styles.linkNumber}>{`0${index + 1}`}</span>
          <span className={styles.linkText}>{title.text}</span>
        </Link>
        {!!childLinks?.length && <span className={`${styles.toggle} toggle`} />}
      </div>
      {!!childLinks?.length && (
        <div className={styles.sublinksContainer}>
          {childLinks.map((item) => {
            const { link_text: text, link_url: childLink } = item;
            return (
              <Link to={childLink?.url} className={styles.sublink}>
                {text?.text}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );

  return (
    <header className={classNames}>
      <div className={styles.header}>
        <div className={`container ${styles.container}`}>
          <Link to="/" className={styles.logoContainer}>
            <Logo className={styles.logo} />
          </Link>
          <nav className={styles.nav} style={showNav ? { height } : {}}>
            {parents.map((item, index) => {
              const { link_text: title, link_url: link } = item;
              const sublinks = navigationLinks.filter((subLink) => subLink?.parent?.url === link?.url);

              return <NavItem title={title} link={link} childLinks={sublinks} index={index} />;
            })}
            <Hamburger
              onClick={toggleNav}
              active={showNav}
              themeClass={showNav || theme === 'light' || scrolledUp ? 'light-mode' : ''}
            />
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
