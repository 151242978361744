import * as React from 'react';
import { PrismicPreviewProvider } from 'components';

export const wrapRootElement = ({ element }) => {
  const isInventiaStaff = window.localStorage.getItem('inventia-staff');
  if (isInventiaStaff) {
    window.gtag('set', { staff: true });
  }
  return <PrismicPreviewProvider element={element} />;
};
