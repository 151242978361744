import React, { Fragment } from 'react';
import { Section, Image, Carousel, Link } from 'components';
import * as styles from './styles.module.scss';

const Awards = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { content, title } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const carouselVariant = items?.length > 3;

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    cssEase: 'ease',
    autoplay: true,
    speed: 600,
    slidesToScroll: 1,
    draggable: true,
    centerMode: false,
    variableWidth: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const ItemContainer = ({ link, children }) => (
    <>
      {link?.url ? (
        <Link className={styles.awardContainer} to={link?.url}>
          {children}
        </Link>
      ) : (
        <div className={styles.awardContainer}>{children}</div>
      )}
    </>
  );

  const Item = ({ item }) => {
    const { award_company: company, award_name: award, image, link } = item;
    return (
      <ItemContainer className={styles.awardContainer} link={link}>
        <div className={styles.imageContainer}>
          <Image className={styles.image} image={image} objectFit="contain" />
        </div>
        {company?.html && <div dangerouslySetInnerHTML={{ __html: company.html }} className={styles.company} />}
        {award?.html && <div dangerouslySetInnerHTML={{ __html: award.html }} className={styles.award} />}
      </ItemContainer>
    );
  };

  const Container = ({ children }) => (
    <>
      {carouselVariant ? (
        <Carousel settings={settings} containerClassName={styles.carousel}>
          {children}
        </Carousel>
      ) : (
        <div className={styles.grid}>{children}</div>
      )}
    </>
  );

  return (
    <Section
      className={`${styles.section} ${carouselVariant ? styles.carouselVariant : ''}`}
      containerClassName={styles.container}
      sliceName="Awards"
    >
      <div className={styles.textContainer}>
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
        {content?.text && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
      </div>
      <Container>
        {items?.map((item) => (
          <Fragment key={item?.award_name?.text}>
            <Item item={item} />
          </Fragment>
        ))}
      </Container>
    </Section>
  );
};

export default Awards;
