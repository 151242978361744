import React from 'react';
import { PostCard } from 'components';
import * as styles from './styles.module.scss';

const FeaturedGrid = ({ posts, className }) => (
  <div className={`${className || ''} ${styles.postsContainer}`}>
    {posts.map((post, index) => {
      const isFirst = index === 0;
      return (
        <PostCard
          key={post?.data?.title?.text}
          isFirst={isFirst}
          post={post}
          uid={post?.uid}
          className={`${isFirst ? styles.firstCard : ''} ${styles.card}`}
          showExcerpt={isFirst}
          readMore={isFirst}
        />
      );
    })}
  </div>
);

export default FeaturedGrid;
