// extracted by mini-css-extract-plugin
export var alignImageTop = "styles-module--alignImageTop--2b5c5";
export var blackBg = "styles-module--blackBg--9572e";
export var button = "styles-module--button--d7928";
export var container = "styles-module--container--f871b";
export var content = "styles-module--content--c8f68";
export var greyBg = "styles-module--greyBg--b5cec";
export var image = "styles-module--image--d40a8";
export var section = "styles-module--section--14d38";
export var textContainer = "styles-module--textContainer--7b40f";
export var title = "styles-module--title--30a80";
export var topImage = "styles-module--topImage--bcc6d";
export var visibleSection = "styles-module--visibleSection--64b82";
export var whiteBg = "styles-module--whiteBg--995f4";