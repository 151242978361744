import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Section, Link, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const Careers = (props) => {
  const { data } = props;
  const { primary } = data;
  const { title, content } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;

  const jobData = useStaticQuery(graphql`
    query jobs {
      allJobs {
        nodes {
          title
          location
          jobLink
          id
          departments {
            name
          }
        }
      }
    }
  `);

  const {
    allJobs: { nodes: jobs },
  } = jobData;

  // populate filters
  const locations = [];
  const teams = [];
  jobs.forEach((job) => {
    const { location, departments } = job;
    if (locations.indexOf(location) === -1 && location !== '') locations.push(location);
    const department = departments[0]?.name; // departments returns array but never is more than one value
    if (teams.indexOf(department) === -1 && department !== '') teams.push(department);
  });

  // set active filters
  const [activeLocation, setActiveLocation] = useState(null);
  const [activeTeam, setActiveTeam] = useState(null);
  const [jobsToUse, setJobsToUse] = useState(jobs);
  const [expanded, setExpanded] = useState(false);

  // handle location filter
  const handleLocation = (event, location) => {
    event.preventDefault();
    if (activeLocation === location) {
      setActiveLocation(null);
    } else {
      setActiveLocation(location);
    }
  };

  // handle team filter
  const handleTeam = (event, team) => {
    event.preventDefault();
    if (activeTeam === team) {
      setActiveTeam(null);
    } else {
      setActiveTeam(team);
    }
  };

  const paginationAmount = 9;

  // handle jobs to show after filter
  useEffect(() => {
    // no filter
    if (!activeTeam && !activeLocation) {
      setJobsToUse(jobs.slice(0, paginationAmount));
      setExpanded(false);
    }
    // single filter [location]
    if (activeTeam && !activeLocation) {
      setJobsToUse(jobs.filter((job) => job.departments[0]?.name === activeTeam));
      setExpanded(true);
    }
    // single filter [team]
    if (activeLocation && !activeTeam) {
      setJobsToUse(jobs.filter((job) => job.location === activeLocation));
      setExpanded(true);
    }
    // both filters
    if (activeTeam && activeLocation) {
      setJobsToUse(jobs.filter((job) => job.location === activeLocation && job.departments[0]?.name === activeTeam));
      setExpanded(true);
    }
  }, [activeTeam, activeLocation]);

  const showAllJobs = (event) => {
    event.preventDefault();
    setJobsToUse(jobs);
  };

  const JobsFilter = ({ values, isLocation }) => (
    <div className={styles.filterContainer}>
      <span className={styles.filterTitle}>Filter roles by {isLocation ? 'location' : 'practice'}</span>
      <div className={styles.buttonsContainer}>
        {values.map((value, index) => (
          <button
            className={`${activeLocation === value || activeTeam === value ? styles.activeButton : ''} ${
              styles.filterButton
            }`}
            type="button"
            onClick={isLocation ? (event) => handleLocation(event, value) : (event) => handleTeam(event, value)}
            key={`${value} ${index}`}
          >
            {value}
          </button>
        ))}
      </div>
    </div>
  );

  return (
    <Section containerClassName={styles.container} className={styles.section} sliceName="Careers">
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
      {/* // jobs filter */}
      <JobsFilter values={locations} isLocation />
      <JobsFilter values={teams} />
      {jobsToUse.length === 0 ? (
        <div className={styles.downStateContainer}>
          <span>No listings available</span>
        </div>
      ) : (
        <div className={styles.grid}>
          {jobsToUse?.map((item, index) => {
            const { departments, location: jobLocation, title: jobTitle, id } = item;
            const departmentsFormated = departments.map((department) => department.name);

            return (
              <OnVisible className={styles.jobContainer} visibleClassName={styles.visibleJob} key={id}>
                <div className={styles.jobTitle}>
                  <span>{jobTitle}</span>
                </div>
                <div className={styles.jobContent}>
                  {departmentsFormated.map((department, i) => (
                    <>
                      <span className={styles.department}>{department}</span>
                      {departmentsFormated.length > 1 && i === departmentsFormated.length - 1 && <span>,</span>}
                    </>
                  ))}
                  <span className={styles.location}>{jobLocation}</span>
                  <Link to={`/careers/${id}`} className={`cta ${styles.cta}`}>
                    Learn more
                  </Link>
                </div>
              </OnVisible>
            );
          })}
        </div>
      )}
      {!expanded && jobs?.length > 9 && (
        <span className={styles.showAll} onClick={showAllJobs}>
          See all {jobs.length} positions
        </span>
      )}
    </Section>
  );
};

export default Careers;
