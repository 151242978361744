import React from 'react';
import { Section, Image } from 'components';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './styles.module.scss';

const ThreeColumnImages = (props) => {
  const { data, isJobs } = props;
  const items = data?.items;

  return (
    <Section
      className={`three-cols-images ${styles.section}`}
      containerClassName={styles.container}
      sliceName="ThreeColumnImages"
    >
      {!isJobs &&
        items.map((item) => {
          const { image } = item;
          return <Image className={styles.image} image={image} key={image.url} />;
        })}
      {isJobs && (
        <>
          <StaticImage alt="team photo" className={styles.image} src="../../images/team-1.jpg" />
          <StaticImage alt="team photo" className={styles.image} src="../../images/team-2.jpeg" />
          <StaticImage alt="team photo" className={styles.image} src="../../images/team-3.jpg" />
        </>
      )}
    </Section>
  );
};

export default ThreeColumnImages;
