// extracted by mini-css-extract-plugin
export var column = "styles-module--column--37432";
export var container = "styles-module--container--bf747";
export var grid = "styles-module--grid--bd516";
export var iconContainer = "styles-module--iconContainer--ba98b";
export var image = "styles-module--image--624ca";
export var name = "styles-module--name--42e44";
export var role = "styles-module--role--01acf";
export var section = "styles-module--section--36492";
export var socialIcon = "styles-module--socialIcon--a5e88";
export var socials = "styles-module--socials--e21f9";
export var title = "styles-module--title--89197";