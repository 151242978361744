export const activeCampaignFields = [
  {
    type: 'select',
    name: 'honorific',
    label: 'Honorific',
    options: ['Please Select', 'Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.'],
    defaultValue: '',
    validation: { required: true },
    validationMessage: 'Please select an honorific',
  },
  {
    type: 'text',
    name: 'firstName',
    placeholder: 'First name',
    className: 'split-left',
    defaultValue: '',
    validation: { required: true },
    validationMessage: 'Please enter your first name',
  },
  {
    type: 'text',
    name: 'lastName',
    placeholder: 'Last name',
    className: 'split-right',
    defaultValue: '',
    validation: { required: true },
    validationMessage: 'Please enter your last name',
  },
  {
    type: 'email',
    name: 'email',
    placeholder: 'Email address',
    defaultValue: '',
    validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
    validationMessage: 'Please enter a valid email',
  },
  {
    type: 'text',
    name: 'position',
    placeholder: 'Position',
    className: 'split-left',
    defaultValue: '',
    validation: { required: true },
    validationMessage: 'Please select your position',
  },
  {
    type: 'text',
    name: 'affiliation',
    placeholder: 'Affiliation',
    defaultValue: '',
    className: 'split-right',
    validation: { required: true },
    validationMessage: 'Please enter your affiliation',
  },
  {
    type: 'select',
    name: 'region',
    label: 'Region',
    options: ['Please Select', 'Asia-Pacific', 'Europe', 'North America', 'Other'],
    defaultValue: '',
    validation: { required: true },
    validationMessage: 'Please enter your region',
  },
  {
    type: 'select',
    name: 'enquiryType',
    label: 'Enquiry Type',
    options: [
      'Please Select',
      'General Enquiry',
      'Sales and Quote Request',
      'Partnerships, Services and Collaborations',
      'Media',
      'Careers',
    ],
    defaultValue: '',
    validation: { required: true },
    validationMessage: 'Please enter your enquiry type',
  },
  {
    type: 'textarea',
    name: 'message',
    placeholder: 'Message',
    defaultValue: '',
    validation: { required: true },
    validationMessage: 'Please enter a message',
  },
];

export const salesforceFields = [
  {
    type: 'text',
    name: 'customerName',
    placeholder: 'Contact Name*',
    defaultValue: '',
    className: 'split-left',
    validation: { required: true },
    validationMessage: 'Please enter your name',
  },
  {
    type: 'text',
    name: 'insituteName',
    placeholder: 'Institution/Company*',
    defaultValue: '',
    className: 'split-right',
    validation: { required: true },
    validationMessage: 'Please enter a company',
  },
  {
    type: 'email',
    name: 'email',
    placeholder: 'Email*',
    defaultValue: '',
    className: 'split-left',
    validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
    validationMessage: 'Please enter a valid email',
  },
  {
    type: 'tel',
    name: 'phone',
    placeholder: 'Contact Number',
    defaultValue: '',
    className: 'split-right',
    validation: { required: false, minLength: 8 },
    validationMessage: 'Please enter a valid number',
  },
  {
    type: 'text',
    name: 'printerName',
    placeholder: 'Printer Name or Serial Number (if known or applicable)',
    defaultValue: '',
    validation: { required: false },
    validationMessage: '',
  },
  {
    type: 'textarea',
    name: 'subject',
    placeholder: 'Subject*',
    defaultValue: '',
    validation: { required: true },
    validationMessage: 'Please enter a subject',
  },
  {
    type: 'textarea',
    name: 'description',
    placeholder: 'Description*',
    defaultValue: '',
    validation: { required: true },
    validationMessage: 'Please enter a description',
  },
  {
    type: 'file',
    name: 'file',
    label: 'Attachment (10 mb max)',
    placeholder: 'Attachment (10 mb max)',
    defaultValue: '',
    validation: {
      required: false,
      validate: {
        lessThan10MB: (files) => !files[0] || files[0]?.size < 10000000,
        acceptedFormats: (files) =>
          !files[0] || ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'].includes(files[0]?.type),
      },
    },
    validationMessage: 'File needs to be less than 10mb and a PNG, PDF or JPG',
  },
];
