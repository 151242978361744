import React, { useState, useEffect } from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const Roadmap = (props) => {
  const { data } = props;
  const { items } = data;

  const [windowHeight, setWindowHeight] = useState(null);
  const [active, setActive] = useState([]);
  const [lastDot, setLastDot] = useState(false);

  const generateId = (text) => (text ? text.replace(/\s+/g, '-').toLowerCase() : '');

  const elementIds = [];

  items.forEach((item, index) => {
    elementIds.push(
      `${generateId('title')}-${index}`,
      `${generateId('step-1')}-${index}`,
      `${generateId('step-2')}-${index}`,
      `${generateId('step-3')}-${index}`
    );
  });

  const getElementOffsets = () => {
    // fill final extra  dot when reached bottom
    if (active.includes('step-3-2')) {
      setLastDot(true);
    } else {
      setLastDot(false);
    }
    const is50Percent = windowHeight / 2;
    const activeElements = [...active];
    elementIds.forEach((id) => {
      const element = document?.getElementById(id);
      const offset = element.getBoundingClientRect().top;
      if (offset <= is50Percent && !activeElements.includes(id)) {
        activeElements.push(id);
        element.className += ' active';
        setActive(activeElements);
      }
      if (offset > is50Percent && activeElements.includes(id)) {
        const index = activeElements.indexOf(id);
        activeElements.splice(index, 1);
        element.classList.remove('active');
        setActive(activeElements);
      }
    });
  };

  // window listener
  useEffect(() => {
    window.addEventListener('resize', () => setWindowHeight(window.innerHeight));
    // Tidy up when unmounting component from DOM
    return () => {
      window.removeEventListener('resize', () => setWindowHeight(window.innerHeight));
    };
  }, [windowHeight]);

  // scroll listener
  useEffect(() => {
    setWindowHeight(window.innerHeight);
    window.addEventListener('scroll', getElementOffsets);
    // Tidy up when unmounting component from DOM
    return () => {
      window.removeEventListener('scroll', getElementOffsets);
    };
  }, [active, windowHeight]);

  const lastDotFill = lastDot ? styles.lastDotFill : '';

  return (
    <Section
      containerClassName={styles.container}
      className={`roadmap ${lastDotFill} ${styles.section}`}
      sliceName="Roadmap"
    >
      <div className={styles.innerContainer}>
        {items.map((item, index) => {
          const { content, step_1: step1, step_2: step2, step_3: step3, title } = item;
          const titleID = `${generateId('title')}-${index}`;
          const step1ID = `${generateId('step-1')}-${index}`;
          const step2ID = `${generateId('step-2')}-${index}`;
          const step3ID = `${generateId('step-3')}-${index}`;
          return (
            <div className={styles.item} key={title.text}>
              <div id={titleID} className={styles.titlePanel}>
                {title?.html && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
                {content?.html && (
                  <div dangerouslySetInnerHTML={{ __html: content?.html }} className={styles.content} />
                )}
              </div>
              <div className={styles.stepsContainer}>
                <div id={step1ID} className={styles.stepPanel}>
                  <span className={styles.stepNumber}>01</span>
                  {step1?.html && <div dangerouslySetInnerHTML={{ __html: step1?.html }} className={styles.step} />}
                </div>
                <div id={step2ID} className={styles.stepPanel}>
                  <span className={styles.stepNumber}>02</span>
                  {step2?.html && <div dangerouslySetInnerHTML={{ __html: step2?.html }} className={styles.step} />}
                </div>
                <div id={step3ID} className={styles.stepPanel}>
                  <span className={styles.stepNumber}>03</span>
                  {step3?.html && <div dangerouslySetInnerHTML={{ __html: step3?.html }} className={styles.step} />}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default Roadmap;
