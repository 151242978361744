import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import { PostGrid, Section, Link } from 'components';
import { ReactComponent as SearchIcon } from 'images/search.svg';
import { useDebounce, useBlogSearchIndex } from 'hooks';
import './styles.scss';

const SearchBar = ({ active, closeSearch, blogPosts }) => {
  const [value, setValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const { blogIndex } = useBlogSearchIndex({ allPrismicResourcePost: blogPosts });

  const debouncedSearchTerm = useDebounce(value, 500);

  const searchInput = useRef(null);

  useEffect(() => {
    if (active) {
      searchInput.current.focus();
    } else {
      setValue('');
    }
  }, [active]);

  const handleChange = (event) => {
    const query = event.target.value;
    setValue(query);
  };

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2 && blogIndex) {
      const search = blogIndex.search(`${value}*`).map((post) => post);
      const matchingPosts = search.map((searchResult) => blogPosts.nodes.find((post) => post.uid === searchResult.ref));
      const top4MatchingPosts = matchingPosts ? [...matchingPosts].slice(0, 6) : [];
      setSearchResults(top4MatchingPosts);
    }

    if (!debouncedSearchTerm || debouncedSearchTerm.length <= 2) {
      setSearchResults([]);
    }
  }, [debouncedSearchTerm, blogIndex]);

  const handleSearch = (event) => {
    event.preventDefault();
    navigate(`/search?q=${value}`);
  };

  return (
    <>
      <Section className={`blog-search-bar ${active ? 'active' : ''}`} largeContainer>
        <div className="blog-search-bar-container">
          <SearchIcon className="blog-search-bar-icon search" />
          <form onSubmit={handleSearch} className="blog-search-bar-form">
            <input
              id="search-input"
              type="search"
              value={value}
              onChange={handleChange}
              placeholder="Search Resources"
              ref={searchInput}
            />
          </form>
          <button className="blog-search-bar-button" type="button" onClick={closeSearch}>
            <div className="blog-search-bar-icon cross">
              <div className="leftright" />
              <div className="rightleft" />
            </div>
          </button>
        </div>
        {searchResults.length === 0 && value && value.length > 2 && <span className="no-results-text">No results</span>}
        {searchResults && searchResults.length > 0 && (
          <div className="blog-search-bar-results">
            <PostGrid posts={searchResults.slice(0, 3)} className="search-posts" />
            {searchResults.length > 3 && (
              <Link
                to={`/search?q=${value}`}
                onClick={() => closeSearch()}
                className="cta gradient"
                type="button"
                arrow="gradient"
              >
                See all results
              </Link>
            )}
          </div>
        )}
      </Section>
    </>
  );
};

export default SearchBar;
