import React from 'react';
import { Section, Image, Form } from 'components';
import * as styles from './styles.module.scss';

const DownloadForm = (props) => {
  const { data } = props;
  const { primary } = data;
  const { form_title: title, download } = primary;

  const titleHtml = title?.html;
  const titleText = title?.text;

  const fields = [
    {
      type: 'text',
      name: 'firstName',
      placeholder: 'First name*',
      className: 'split-left',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your first name',
    },
    {
      type: 'text',
      name: 'lastName',
      placeholder: 'Last name*',
      className: 'split-right',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your last name',
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'Email*',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
    },
    {
      type: 'hidden',
      name: 'downloadUrl',
      defaultValue: download?.url,
    },
    {
      type: 'hidden',
      name: 'downloadTitle',
      defaultValue: titleText,
    },
  ];

  // need logic for download form

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="DownloadForm">
      <div className={styles.form}>
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
        <Form
          fields={fields}
          isButton
          buttonText="Download Now"
          successMessage="Thank you for downloading! It should arrive in your inbox shortly!"
          tag={14}
          list={6}
        />
      </div>
    </Section>
  );
};

export default DownloadForm;
