import React, { useState, useEffect } from 'react';
import { Section, Link, Modal, CircleWithLine } from 'components';
import classNames from 'classnames';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { ReactComponent as PlayButton } from 'images/play-button.svg';
import Vimeo from '@u-wave/react-vimeo';
import * as styles from './styles.module.scss';

const Header = (props) => {
  const { data, isResources, isJobs } = props;
  const { primary, id } = data;
  const {
    background_image: bgImage,
    background_video: bgVideo,
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    title,
    modal_video: modalVideo,
    background_colour: backgroundColor,
    center_align_content: centerAlignment,
    text_colour: textColor,
    spacing,
    spacing_override: spacingOverride,
    remove_scroll_indicator: removeScrollIndicator,
  } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasCta = ctaText?.text && ctaLink?.url;
  const hasModalVideo = ctaText?.text && modalVideo?.url;

  // variants
  const hasVideo = bgVideo?.url;
  const hasBgImage = bgImage?.url;
  const hasNoMedia = !hasVideo && !hasBgImage;
  const gradientBg = isJobs;

  // variant classes
  const sectionClassNames = classNames(`inventia-header ${styles.section}`, {
    [styles.centerAlignment]: centerAlignment || hasVideo,
    [styles.leftAlignment]: !centerAlignment && !hasVideo,
    [styles.hasVideo]: hasVideo,
    [styles.hasImage]: hasBgImage || gradientBg,
    [styles.noBg]: hasNoMedia && !gradientBg && !backgroundColor,
    [styles.gradientBg]: gradientBg,
    [styles.darkText]: textColor === 'Dark',
  });

  // MODAL LOGIC
  const [showModal, setShowModal] = useState(false);
  const [bodyLocked, setBodyLock] = useState(false); // lock body while showModal = true

  const openModal = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  const lockBody = () => {
    // lock body while modal open
    document.body.classList.add('modal-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    // when modal closed, unlock body
    document.body.classList.remove('modal-open');
    setBodyLock(false);
  };
  // use effect when showmodal updated
  useEffect(() => {
    if (showModal && !bodyLocked) lockBody();
    if (!showModal && bodyLocked) unlockBody();
  }, [showModal]);

  // scroll to next slice logic
  const scrollToNext = (event) => {
    event.preventDefault();
    const { nextSibling } = document.getElementById(id);
    if (nextSibling) smoothScrollIntoView(nextSibling, { behavior: 'smooth', block: 'start' });
  };

  return (
    <Section
      containerClassName={styles.container}
      sliceName="Header"
      className={sectionClassNames}
      style={{ backgroundColor }}
      bgImage={hasBgImage ? bgImage : ''}
      spacing={spacing || 'Large'}
      spacingOverride={spacingOverride}
      id={id}
    >
      {!isResources && !removeScrollIndicator && (
        <CircleWithLine onClick={scrollToNext} className={styles.indicator} pulse />
      )}
      {hasVideo && !hasBgImage && (
        <div className={styles.videoContainer} style={{ backgroundColor }}>
          <Vimeo autoplay video={bgVideo.url} id="video" loop muted controls={false} className="vimeo-video" />
        </div>
      )}
      <div className={styles.textContainer}>
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
        {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
        {hasCta && (
          <Link className={`button ${styles.cta}`} to={ctaLink.url}>
            {ctaText.text}
          </Link>
        )}
        {hasModalVideo && (
          <>
            <Link to="#openVideo" className={`button ${styles.videoCta}`} onClick={openModal}>
              <div className={styles.playIconContainer}>
                <PlayButton className={styles.playIcon} />
              </div>
              {ctaText.text}
            </Link>
            <Modal active={showModal} closeModal={closeModal} className={styles.modal} title="close">
              <div className={styles.modalVideo}>
                <Vimeo autoplay video={modalVideo.url} id="video" loop muted className="vimeo-video-modal" />
              </div>
            </Modal>
          </>
        )}
      </div>
    </Section>
  );
};

export default Header;
