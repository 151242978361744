import React from 'react';
import classNames from 'classnames';
import { OnVisible } from 'components';
// import * as styles from './styles.module.scss';

const RichText = React.forwardRef(
  ({
    html,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p1,
    p2,
    p3,
    className,
    visibleClassName,
    useVisible,
    subheading,
    style,
    richTextRef,
  }) => {
    if (!html || html.length < 7) return null;

    const combinedClassNames = classNames('rich-text', {
      [className]: className,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p1,
      p2,
      p3,
      subheading,
    });

    const cleanedHtml = html.replace(/target="undefined"/g, '');
 

    if (useVisible) {
      return (
        <OnVisible
          ref={richTextRef || null}
          className={combinedClassNames}
          visibleClassName={visibleClassName || ''}
          dangerouslySetInnerHTML={{ __html: cleanedHtml }}
          style={style || {}}
        />
      );
    }

    return (
      <div
        className={combinedClassNames}
        dangerouslySetInnerHTML={{ __html: cleanedHtml }}
        ref={richTextRef || null}
        style={style || {}}
      />
    );
  }
);

export default RichText;
