import React from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const Wysiwyg = (props) => {
  const { data } = props;
  const { primary } = data;
  const { wysiwyg } = primary;

  // content check
  const wysiwygHtml = wysiwyg?.html;

  return (
    <Section className={styles.wysiwyg} noContainer>
      {wysiwygHtml && (
        <div
          className={styles.wysiwygContent}
          dangerouslySetInnerHTML={{
            __html: wysiwygHtml,
          }}
        />
      )}
    </Section>
  );
};

export default Wysiwyg;
