import React, { Fragment } from 'react';
import { Section, Image } from 'components';
import * as styles from './styles.module.scss';

const InfoColumns = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { content, grey_background: greyBg, icon, title } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasIcon = icon?.url;
  const darkBg = greyBg ? styles.darkBg : '';

  return (
    <Section
      containerClassName={styles.container}
      sliceName="InfoColumns"
      className={`info-columns ${darkBg} ${styles.section}`}
    >
      <div className={styles.innerContainer}>
        <div className={styles.leftCol}>{hasIcon && <Image className={styles.icon} image={icon} />}</div>
        <div className={styles.rightCol}>
          {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
          {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
          <div className={styles.dotPointsContainer}>
            {items.map((item) => {
              const { dot_point: dotPoint } = item;
              return (
                <Fragment key={dotPoint.text}>
                  {dotPoint?.html && (
                    <div dangerouslySetInnerHTML={{ __html: dotPoint.html }} className={styles.dotpoint} />
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default InfoColumns;
