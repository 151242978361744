import React from 'react';
import { Image, Section, Link } from 'components';
import * as styles from './styles.module.scss';

const ImageSlice = (props) => {
  const { data } = props;
  const { primary } = data;
  const { video_url: video } = primary;

  if (!video?.html) return null;

  const aspectRatioPadding = ((Math.round(video.height) / Math.round(video.width)) * 100).toFixed(2);

  return (
    <Section className={styles.videoSlice} noContainer>
      <div
        className={styles.videoContainer}
        style={{ paddingBottom: `${aspectRatioPadding}%` }}
        dangerouslySetInnerHTML={{ __html: video.html }}
      />
    </Section>
  );
};

export default ImageSlice;
