import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FormField } from 'components';
import * as styles from './styles.module.scss';

const getDataUrl = (file) =>
  // Return a promise per file
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      try {
        const base64DataURL = e.target.result;
        const base64 = 'base64,';
        const fileStart = base64DataURL.indexOf(base64) + base64.length;
        // Resolve the promise with the response value
        resolve(base64DataURL.substring(fileStart));
      } catch (err) {
        reject(err);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

const Form = ({ content, location, fields, isButton, buttonText, successMessage, list, tag, type, className }) => {
  const { register, handleSubmit, formState, reset } = useForm({ mode: 'onChange' });

  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { errors, dirtyFields } = formState;

  useEffect(
    () =>
      // Set submitting to false in clean up function
      () => {
        setSubmitting(false);
      },
    []
  );

  const onError = async (values) => {
    try {
      const formSparkIdToUse = type === 'Salesforce' ? 'CTIsdQGP' : 'sWE2sepB';
      const url = `https://submit-form.com/${formSparkIdToUse}`; // pass formSparkId to send to formSpark
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(values),
      };
      await fetch(url, config);
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmissionError('Oops something went wrong, please try again');
    }
  };

  // salesforce on submit
  const onSubmitSF = async (values) => {
    const { customerName, insituteName, email, phone, printerName, subject, description, file } = values;
    const fileObj = file?.[0];
    const dataUrl = fileObj ? await getDataUrl(fileObj) : null;

    const data = {
      customerName,
      insituteName,
      email,
      phone,
      printerName,
      subject,
      description,
      file: dataUrl,
      fileName: fileObj?.name,
    };

    setSubmitting(true);
    setSubmissionError(false);
    setSubmitted(false);

    try {
      await axios.post('https://eo50a3eekmt25on.m.pipedream.net?pipedream_upload_body=1', data);
      // ON SUCCESS
      setSubmitting(false);
      setSubmitted(true);
      reset({});
      // // GTM DATALAYER PUSH
      if (window.dataLayer) {
        console.log(`[sending to dataLayer]: conversion `);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'conversion' });
      }
    } catch (error) {
      console.error('ERROR sending case to Salesforce', error);
      // ON FAIL
      const { file: _, ...formSparkValues } = values;
      await onError({ ...formSparkValues, form: 'Salesforce' });
      setSubmitting(false);
      setSubmissionError('Uh oh something went wrong :(');
    }
  };

  // active campaign on submit
  const onSubmitAC = async (values) => {
    const {
      email,
      firstName,
      lastName,
      downloadUrl,
      downloadTitle,
      affiliation,
      honorific,
      position,
      region,
      message,
      enquiryType,
    } = values;

    const fieldValues = [];

    const addFields = (field, value) => {
      fieldValues.push({
        field,
        value,
      });
    };

    // brochure fields
    if (downloadUrl) {
      addFields('8', downloadUrl);
    }
    if (downloadTitle) {
      addFields('10', downloadTitle);
    }
    if (affiliation) {
      addFields('9', affiliation);
    }
    // contact form fields
    if (honorific) {
      addFields('13', honorific);
    }
    if (position) {
      addFields('14', position);
    }
    if (region) {
      addFields('19', region);
    }
    if (message) {
      addFields('11', message);
    }
    if (enquiryType) {
      addFields('21', enquiryType);
    }

    const contact = {
      email,
      firstName,
      lastName,
      fieldValues,
    };

    setSubmitting(true);
    setSubmissionError(false);
    setSubmitted(false);

    const activeCampaignBody = {
      contact,
      list,
      tag,
    };

    try {
      setSubmitting(true);
      await axios.post(`${process.env.GATSBY_SITE_URL}/api/active-campaign`, activeCampaignBody);
      // ON SUCCESS
      setSubmitting(false);
      setSubmitted(true);
      reset({});
      // // GTM DATALAYER PUSH
      if (window.dataLayer) {
        console.log(`[sending to dataLayer]: conversion `);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'conversion' });
      }
    } catch (error) {
      console.error('ERROR sending email to Active Campaign', error);
      // ON FAIL
      await onError({ ...values, form: 'Active Campaign' });
      setSubmitting(false);
      setSubmissionError('Uh oh something went wrong :(');
    }
  };

  const successMessageToUse = successMessage || 'Thank you for subscribing';

  const onSubmitToUse = type === 'Salesforce' ? onSubmitSF : onSubmitAC;

  const firstFieldWithError = errors ? Object.keys(errors)[0] : null;

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmitToUse)} className={`${styles.form} ${className}`}>
        <button type="submit" disabled aria-hidden="true" style={{ display: 'none' }} />
        {fields.map((field) => {
          const hasError = errors[field.name] && firstFieldWithError === field.name;
          return (
            <div key={field.label} className={`${styles.field} ${field.className || ''}`}>
              <span className={styles.label}>{field.label}</span>
              <FormField {...field} register={register} />
              <div className={`${styles.fieldError} ${hasError ? 'active' : 'inactive'}`}>
                {hasError && <span>{field.validationMessage || 'This field is required'}</span>}
              </div>
            </div>
          );
        })}
        <div>
          <button
            type="submit"
            className={`${isButton ? 'button primary' : 'cta'} ${styles.formButton}`}
            disabled={submitting}
          >
            {submitting ? 'Submitting' : buttonText || 'Submit'}
          </button>
        </div>
        {submitted && <span className={styles.sucessMessage}>{successMessageToUse}</span>}
        {submissionError && <span className={styles.errorMessage}>{submissionError}</span>}
      </form>
    </section>
  );
};

export default Form;
