module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KDLJCFZ","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"() => {\n          const queryStrings = (document && document.location && document.location.search) || {};\n\n          window.utms = queryStrings;\n\n          const queriesObj = queryStrings ? Object.fromEntries(new URLSearchParams(queryStrings)) : {};\n\n          return {\n            platform: `gatsby`,\n            ...queriesObj,\n          };\n        }"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-QJZ42TS7TS"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"inventia-life","promptForAccessToken":true,"apiEndpoint":"https://inventia-life.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../plugins/wm-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://inventia.life"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Revolutionising 3D cell biology","short_name":"Inventia","description":"It is well established that 3D cell models better represent human tissues. They more accurately replicate biological processes, disease physiology and drug responses. The age of 2D cell culture is over.","start_url":"/","background_color":"#EC0182","theme_color":"#EC0182","display":"standalone","icon":"src/images/icon.png","include_favicon":false,"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"96a900dced48663f5a498624822e7066"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
