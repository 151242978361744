import React from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const DotPoints = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, background } = primary;

  const titleHtml = title?.html;
  const backgrounds = {
    Black: styles.blackBg,
    White: styles.whiteBg,
    Grey: styles.greyBg,
  };

  return (
    <Section
      containerClassName={styles.container}
      className={`dot-points ${backgrounds[background] || styles.blackBg} ${styles.section}`}
      sliceName="DotPoints"
    >
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      {items.map((item) => {
        const { dot_point: dotPoint } = item;
        return (
          <div key={dotPoint.text}>
            {dotPoint?.text && <div dangerouslySetInnerHTML={{ __html: dotPoint.html }} className={styles.dotPoint} />}
          </div>
        );
      })}
    </Section>
  );
};

export default DotPoints;
