import React from 'react';
import { Section, Image, Form } from 'components';
import * as styles from './styles.module.scss';

const BrochureSignUp = (props) => {
  const { data } = props;
  const { primary } = data;
  const { content, image, title } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasImage = image?.url;

  const fields = [
    {
      type: 'text',
      name: 'firstName',
      placeholder: 'First name',
      className: 'split-left',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your first name',
    },
    {
      type: 'text',
      name: 'lastName',
      placeholder: 'Last name',
      className: 'split-right',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your last name',
    },
    {
      type: 'text',
      name: 'affiliation',
      placeholder: 'Affiliation',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your affiliation',
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'Email address',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
    },
  ];

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="BrochureSignUp">
      <div className={styles.imageContainer}>{hasImage && <Image className={styles.image} image={image} />}</div>
      <div className={styles.form}>
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
        {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
        <Form fields={fields} tag={15} list={1} />
      </div>
    </Section>
  );
};

export default BrochureSignUp;
