import React, { useState } from 'react';
import { Section, Image, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const ImageSlice = (props) => {
  const { data } = props;
  const { primary } = data;
  const { image, background } = primary;

  if (!image?.url) return null;

  const backgrounds = {
    Black: styles.blackBg,
    White: styles.whiteBg,
    Grey: styles.greyBg,
    'Half Half Black': styles.halfBlack,
    'Half Half Grey': styles.halfGrey,
  };

  return (
    <Section
      containerClassName={styles.container}
      className={`image-slice ${backgrounds[background] || styles.halfBlack}`}
      sliceName="ImageSlice"
    >
      <OnVisible visibleClassName={styles.visibleImage}>
        <Image className={styles.image} image={image} />
      </OnVisible>
    </Section>
  );
};

export default ImageSlice;
