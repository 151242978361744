// extracted by mini-css-extract-plugin
export var accordionContainer = "styles-module--accordionContainer--66708";
export var accordionItem = "styles-module--accordionItem--9d389";
export var accordionItemToggle = "styles-module--accordionItemToggle--89037";
export var accordionItemToggleContainer = "styles-module--accordionItemToggleContainer--0ad9e";
export var active = "styles-module--active--1e802";
export var answer = "styles-module--answer--2c2c5";
export var content = "styles-module--content--b762e";
export var inactive = "styles-module--inactive--cad73";
export var question = "styles-module--question--7d5c5";
export var title = "styles-module--title--c3f8b";
export var titleContainer = "styles-module--titleContainer--93c19";