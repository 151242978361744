import React from 'react';
import { Section, Image, OnVisible, Link } from 'components';
import * as styles from './styles.module.scss';

const FeatureCards = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, background } = primary;

  const titleHtml = title?.html;

  const backgrounds = {
    Black: styles.blackBg,
    White: styles.whiteBg,
    Grey: styles.greyBg,
  };

  return (
    <Section
      containerClassName={styles.container}
      sliceName="FeatureCards"
      className={`feature-cards-slice ${styles.section} ${backgrounds[background]}`}
    >
      {title.text && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      <OnVisible className={styles.cardGrid} visibleClassName={styles.visibleCards}>
        {items.map((item, index) => {
          const { title: cardTitle, content, icon, cta_link: ctaLink, cta_text: ctaText } = item;
          const hasCta = ctaLink?.url && ctaText?.text;
          return (
            <div className={styles.card} style={{ transitionDelay: `${index * 0.17}s` }} key={cardTitle.text}>
              {icon?.url && <Image className={styles.icon} image={icon} />}
              {cardTitle?.html && (
                <div dangerouslySetInnerHTML={{ __html: cardTitle?.html }} className={styles.cardTitle} />
              )}
              {content?.html && (
                <div dangerouslySetInnerHTML={{ __html: content?.html }} className={styles.cardContent} />
              )}
              {hasCta && (
                <Link className={`button ${styles.cta}`} to={ctaLink.url}>
                  {ctaText.text}
                </Link>
              )}
            </div>
          );
        })}
      </OnVisible>
    </Section>
  );
};

export default FeatureCards;
