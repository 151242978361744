import React from 'react';
import { Section, Image, Link } from 'components';
import { ReactComponent as Email } from 'images/email.svg';
import { ReactComponent as LinkedIn } from 'images/linkedIn.svg';
import * as styles from './styles.module.scss';

const TeamGrid = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title } = primary;

  const titleHtml = title?.html;

  return (
    <Section containerClassName={styles.container} sliceName="TeamGrid" className={styles.section}>
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      <div className={styles.grid}>
        {items.map((item) => {
          const { email, image, linked_in: linkedIn, name, role } = item;
          return (
            <div className={styles.column} key={name.text}>
              <Image className={styles.image} image={image} />
              {name?.html && <div dangerouslySetInnerHTML={{ __html: name?.html }} className={styles.name} />}
              {role?.html && <div dangerouslySetInnerHTML={{ __html: role?.html }} className={styles.role} />}
              <div className={styles.socials}>
                <a href={email?.url} className={styles.iconContainer}>
                  <Email className={styles.socialIcon} />
                </a>
                <Link to={linkedIn?.url} className={styles.iconContainer}>
                  <LinkedIn className={styles.socialIcon} />
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default TeamGrid;
