// extracted by mini-css-extract-plugin
export var content = "styles-module--content--3b936";
export var innerContainer = "styles-module--innerContainer--76c70";
export var item = "styles-module--item--2657a";
export var lastDotFill = "styles-module--lastDotFill--b7676";
export var section = "styles-module--section--d9418";
export var step = "styles-module--step--d1f9b";
export var stepNumber = "styles-module--stepNumber--1d883";
export var stepPanel = "styles-module--stepPanel--615c0";
export var title = "styles-module--title--f0568";
export var titlePanel = "styles-module--titlePanel--9ba82";