import React from 'react';
import { Section, Image, Link } from 'components';
import * as styles from './styles.module.scss';

const ResourcePostHeader = ({ category, source, title, image, excerpt }) => {
  const categoryName = category?.document?.data?.category_name;
  const categoryLink = category?.document?.uid;
  const sourceHtml = source?.html;
  return (
    <Section className={styles.section} containerClassName={styles.container} largeContainer>
      <div className={styles.imageContainer}>{image?.url && <Image image={image} className={styles.image} />}</div>
      <div className={styles.textContainer}>
        {categoryName?.text && (
          <div className={styles.categories}>
            <Link to={`/resources/${categoryLink}/`} className={styles.category}>
              {categoryName.text}
            </Link>
            {sourceHtml && (
              <span
                className={`${styles.category} ${styles.source}`}
                dangerouslySetInnerHTML={{ __html: sourceHtml }}
              />
            )}
          </div>
        )}
        {title?.text && <div dangerouslySetInnerHTML={{ __html: title.html }} className={styles.title} />}
        {excerpt?.text && <div dangerouslySetInnerHTML={{ __html: excerpt.html }} className={styles.excerpt} />}
      </div>
    </Section>
  );
};

export default ResourcePostHeader;
