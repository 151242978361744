function isEmptyHtml(htmlString) {
  try {
    // Remove all HTML tags
    const textContent = htmlString?.replace(/<[^>]*>/g, '');
    return !textContent?.trim();
  } catch (error) {
    console.error(error);
    return false;
  }
}

export default isEmptyHtml;
