import React from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const TwoColTitleText = (props) => {
  const { data } = props;
  const { primary } = data;
  const { title, content } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const noTitle = !title?.text ? styles.noTitleVariant : '';

  return (
    <Section
      className={`${noTitle} ${styles.section}`}
      containerClassName={styles.container}
      sliceName="TwoColTitleText"
    >
      {title?.text ? <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} /> : <div />}
      {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
    </Section>
  );
};

export default TwoColTitleText;
