import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import queryString from 'query-string';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, Slice, TableOfContents } from 'components';
import { Header } from 'slices';
import * as styles from './page.module.scss';

const cleanSliceData = (data) => {
  const stringifiedData = JSON.stringify(data);
  // Remove all instances of target=undefined from the input string
  const cleanedInput = stringifiedData.replace(/\btarget=\\"?undefined\\"?\s*/gi, '');
  // Regular expression to match links with an ?anchor= query string
  const regex = /\b(https?:\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\/[^?\s]+)?\?anchor=([^\s&]+)/g;

  // Replacement pattern to keep only the captured pathname and ?anchor= query string
  const replacement = '$2?anchor=$3';

  // Use the replace() method to apply the regular expression and replacement pattern to all matches in the input string
  const cleanedData = cleanedInput.replace(regex, replacement);
  return JSON.parse(cleanedData);
};

const Page = ({ location, data }) => {
  const { page } = data;
  const { pathname } = location;
  const { data: pageData } = page;
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    use_dark_header: useDarkHeader,
    schema,
  } = pageData;

  // seo
  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    schema: schema.text,
  };

  const anchorPoints = [];
  sliceData.forEach((slice) => {
    const { slice_type: sliceType } = slice;
    if (sliceType === 'resources_accordion') {
      anchorPoints.push(slice?.primary?.title?.text);
    }
  });

  const isResources = pathname.includes('resources');
  const headerSlice = sliceData.filter((slice) => slice?.slice_type === 'header');
  const headerData = headerSlice[0];

  useEffect(() => {
    const queryStrings = location && location.search ? queryString.parse(location.search) : {};
    const { anchor } = queryStrings;
    if (anchor) scrollToElement(anchor);
  }, [location]);

  const scrollToElement = (scrollTo) => {
    if (!scrollTo) return null;
    // remove trailing slash from querystring (form/ becomes form)
    const strippedScrollTo = scrollTo.replace(/\/$/, '');
    const element = document.getElementById(strippedScrollTo);
    if (!element) return null;
    return scrollIntoView(element, { behavior: 'smooth', block: 'start' });
  };

  return (
    <Layout location={location} seo={seo} theme={isResources || useDarkHeader ? 'light' : ''}>
      {isResources && <Header data={headerData} isResources={isResources} />}
      <div className={`container ${isResources ? styles.resourcePage : ''} ${styles.pageContainer}`}>
        <div className={styles.sidebarContainer}>
          <TableOfContents className={styles.tableOfContents} anchorPoints={anchorPoints} />
        </div>
        <div className={styles.sliceContainer}>
          {sliceData.map((slice) => {
            if (slice.slice_type === 'header' && isResources) return null;
            return <Slice key={slice.id} data={cleanSliceData(slice)} />;
          })}
        </div>
      </div>
    </Layout>
  );
};

export default withPrismicPreview(Page);

export const pageQuery = graphql`
  query PageBySlug($uid: String!, $lang: String!) {
    page: prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      id
      uid
      prismicId
      _previewable
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        use_dark_header
        body {
          ...AnchorPointFragment
          ...HeaderPageFragment
          ...VerticalCarouselFragment
          ...TitleAndCtaFragment
          ...FeatureCardsFragment
          ...BrochureSignUpFragment
          ...DotPointsFragment
          ...TwoColTitleTextFragment
          ...HoverGridFragment
          ...ImagePageFragment
          ...FullWidthImageTextFragment
          ...RoadmapFragment
          ...TaglineTitleLogosImageFragment
          ...ProductsGridFragment
          ...InfoColumnsFragment
          ...ResourcesAccordioFragment
          ...ThreeColumnImagesFragment
          ...TeamGridFragment
          ...LogosRowsFragment
          ...CareersFragment
          ...ContactFragment
          ...AwardsFragment
          ...PageWysiwyg
          ...ContactFormFragment
          ...VideoPageFragment
          ...SimpleHeaderPageFragment
          ...TwoColsTextImagePageFragment
          ...CarouselFragment
          ...TestimonialCarouselFragment
        }
      }
    }
  }
`;
