// extracted by mini-css-extract-plugin
export var centerAlignment = "styles-module--centerAlignment--fd509";
export var container = "styles-module--container--b08bc";
export var content = "styles-module--content--6af6a";
export var cta = "styles-module--cta--bf7eb";
export var darkText = "styles-module--darkText--d175b";
export var gradientBg = "styles-module--gradientBg--0124f";
export var hasImage = "styles-module--hasImage--d77d2";
export var hasVideo = "styles-module--hasVideo--389db";
export var indicator = "styles-module--indicator--cc199";
export var leftAlignment = "styles-module--leftAlignment--175a8";
export var modal = "styles-module--modal--47191";
export var modalVideo = "styles-module--modalVideo--dfcb3";
export var noBg = "styles-module--noBg--e6e9b";
export var playIcon = "styles-module--playIcon--8ee3c";
export var playIconContainer = "styles-module--playIconContainer--da8d8";
export var section = "styles-module--section--74dc4";
export var textContainer = "styles-module--textContainer--18748";
export var title = "styles-module--title--c6e22";
export var videoContainer = "styles-module--videoContainer--09b4f";
export var videoCta = "styles-module--videoCta--d9066";