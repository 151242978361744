const generatePath = require('./generatePath');

module.exports = (doc) => {
  const { uid } = doc;
  // Example of link resolver for other post type
  // --------------------------------------------
  if (doc.type === 'resource_post' || doc.type === 'resource_category') {
    return `/resources${generatePath(uid)}`;
  }
  return generatePath(uid);
};
