import React, { useState } from 'react';
import { Section, Link, Image, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const TitleAndCta = (props) => {
  const { data } = props;
  const { primary } = data;
  const { content, cta_link: ctaLink, cta_text: ctaText, background, image, title, pin_image_top: imageTop } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasImage = image?.url;
  const hasCta = ctaLink?.url && ctaText?.text;

  // variant classNames
  const topImage = hasImage ? styles.topImage : '';
  const backgrounds = {
    Black: styles.blackBg,
    White: styles.whiteBg,
    Grey: styles.greyBg,
  };

  const [visible, setVisible] = useState(null);

  const checkVisibility = (isVisible) => {
    setVisible(isVisible);
  };

  const outerVisible = visible ? styles.visibleSection : '';
  const alignImageTop = imageTop ? styles.alignImageTop : '';

  return (
    <Section
      containerClassName={styles.container}
      sliceName="TitleAndCta"
      className={`title-and-cta ${outerVisible} ${alignImageTop} ${topImage} ${
        backgrounds[background] || styles.blackBg
      } ${styles.section}`}
    >
      <OnVisible onChange={checkVisibility}>
        {hasImage && <Image className={styles.image} image={image} objectFit="CONTAIN" />}
        <div className={styles.textContainer}>
          {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
          {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
          {hasCta && (
            <Link className={`${topImage ? 'cta' : 'button primary inverse'} ${styles.button}`} to={ctaLink.url}>
              {ctaText.text}
            </Link>
          )}
        </div>
      </OnVisible>
    </Section>
  );
};

export default TitleAndCta;
