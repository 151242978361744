// extracted by mini-css-extract-plugin
export var cardContainer = "styles-module--cardContainer--7dec2";
export var categories = "styles-module--categories--49d53";
export var category = "styles-module--category--ecf3f";
export var darkmode = "styles-module--darkmode--50054";
export var excerpt = "styles-module--excerpt--196a0";
export var firstCard = "styles-module--firstCard--7dbe0";
export var image = "styles-module--image--111aa";
export var imageContainer = "styles-module--imageContainer--61f45";
export var readMore = "styles-module--readMore--b42a9";
export var sidebar = "styles-module--sidebar--e8917";
export var source = "styles-module--source--26cc6";
export var textContainer = "styles-module--textContainer--f0008";
export var title = "styles-module--title--09fc7";
export var vertical = "styles-module--vertical--3356d";