import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { Navigator, Link } from 'components';
import { ReactComponent as Facebook } from 'images/facebook.svg';
import { ReactComponent as Linkedin } from 'images/linkedIn.svg';
import { ReactComponent as Twitter } from 'images/twitter.svg';
import { ReactComponent as Youtube } from 'images/youtube.svg';

import * as styles from './styles.module.scss';

const Footer = () => {
  const staticData = useStaticQuery(graphql`
    query settings {
      allPrismicSettings {
        nodes {
          lang
          _previewable
          lang
          data {
            facebook {
              url
            }
            linkedin {
              url
            }
            youtube {
              url
            }
            twitter {
              url
            }
            policy_documents {
              document_link {
                url
              }
              document_text {
                html
                text
              }
            }
            navigation_links {
              link_text {
                html
                text
              }
              link_url {
                url
              }
              parent {
                url
              }
            }
          }
        }
      }
    }
  `);

  const langSettingsData = staticData.allPrismicSettings.nodes.find(
    (node) => node.lang === process.env.GATSBY_SITE_LANG
  );

  const { data: settingsData } = useMergePrismicPreviewData(langSettingsData);

  const {
    data: {
      facebook,
      linkedin,
      youtube,
      twitter,
      policy_documents: policyDocuments,
      navigation_links: navigationLinks,
    },
  } = settingsData;

  return (
    <footer className={styles.footer}>
      <Navigator links={navigationLinks} />
      <div className={`container ${styles.socialsContainer}`}>
        <div className={styles.socials}>
          <Link to={facebook.url} className={styles.socialLink}>
            <Facebook className={styles.socialIcon} />
          </Link>
          <Link to={twitter.url} className={styles.socialLink}>
            <Twitter className={styles.socialIcon} />
          </Link>
          <Link to={linkedin.url} className={styles.socialLink}>
            <Linkedin className={styles.socialIcon} />
          </Link>
          <Link to={youtube.url} className={styles.socialLink}>
            <Youtube className={styles.socialIcon} />
          </Link>
        </div>
      </div>
      <div className={`container ${styles.footerContainer}`}>
        <span className={styles.legal}>
          © {new Date().getFullYear()} Inventia Life Science Pty Ltd. All rights reserved.
        </span>
        <div className={styles.policies}>
          {policyDocuments.map((doc) => {
            const { document_link: docLink, document_text: docText } = doc;
            return (
              <Link to={docLink.url} className={styles.policyLink} key={docText.text}>
                {docText.text}
              </Link>
            );
          })}
          <Link to="https://www.mammoth.tech" className={styles.policyLink}>
            Made by Mammoths
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
