// extracted by mini-css-extract-plugin
export var activeLink = "styles-module--activeLink--1ba14";
export var container = "styles-module--container--35a79";
export var indicator = "styles-module--indicator--0dcd1";
export var linkContainer = "styles-module--linkContainer--3c977";
export var linkNumber = "styles-module--linkNumber--f21b3";
export var linkText = "styles-module--linkText--eeef7";
export var logo = "styles-module--logo--70ffa";
export var navLink = "styles-module--navLink--3bfcd";
export var navLinks = "styles-module--navLinks--aef5a";
export var section = "styles-module--section--ab1f9";
export var sublink = "styles-module--sublink--10152";
export var sublinksContainer = "styles-module--sublinksContainer--dc36f";
export var toggle = "styles-module--toggle--f9f0c";