import React from 'react';
import { Image, Link } from 'components';
import * as styles from './styles.module.scss';

const PostCard = ({
  post = {},
  readMore,
  className,
  showExcerpt,
  latestPosts,
  isThreeCols,
  sidebar,
  darkMode,
  vertical,
  isFirst,
}) => {
  const { data, uid } = post;
  const {
    title,
    featured_image: image,
    excerpt,
    date,
    source,
    category: blogCategory,
    alternative_link: alternativeLink,
  } = data;

  const categoryLink = blogCategory?.document?.uid;
  const category = blogCategory?.document?.data?.category_name?.text;
  const sourceHtml = source?.html;
  // rendering variants
  const latestPostsVariant = latestPosts ? styles.latest : '';
  const threeColsVariant = isThreeCols ? styles.threeCols : '';
  const sideBarVariant = sidebar ? styles.sidebar : '';
  const verticalVariant = vertical ? styles.vertical : '';
  const firstCard = isFirst ? styles.firstCard : '';
  const classNames = `${
    className || ''
  } ${verticalVariant} ${firstCard} ${latestPostsVariant} ${threeColsVariant} ${sideBarVariant}`;

  return (
    <div className={`${styles.cardContainer} ${classNames} ${darkMode ? styles.darkmode : ''} `}>
      {image && (
        <Link to={alternativeLink?.url || `/resources/${uid}/`} className={styles.imageContainer}>
          <Image image={image} className={styles.image} objectFit="COVER" />
        </Link>
      )}
      <div className={styles.textContainer}>
        {category && (
          <div className={styles.categories}>
            <Link to={`/resources/${categoryLink}/`} className={styles.category}>
              {category}
            </Link>
            {sourceHtml && (
              <span
                className={`${styles.category} ${styles.source}`}
                dangerouslySetInnerHTML={{ __html: sourceHtml }}
              />
            )}
          </div>
        )}
        {title?.text && (
          <Link to={alternativeLink?.url || `/resources/${uid}/`}>
            <span className={styles.title}>{title.text}</span>
          </Link>
        )}
        {showExcerpt && excerpt?.text && (
          <div className={styles.excerpt} dangerouslySetInnerHTML={{ __html: excerpt?.html }} />
        )}
        {readMore && (
          <Link className={styles.readMore} to={alternativeLink?.url || `/resources/${uid}/`}>
            Read More
          </Link>
        )}
      </div>
    </div>
  );
};

export default PostCard;
