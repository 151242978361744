import React from 'react';
import { Section, Image, Form } from 'components';
import { activeCampaignFields, salesforceFields } from './fields';
import * as styles from './styles.module.scss';

const ContactForm = (props) => {
  const { data } = props;
  const { primary } = data;
  const { content, image, title, form_type: type } = primary;

  const formFields = {
    Contact: { fields: activeCampaignFields, list: 7, tag: 41 },
    Salesforce: { fields: salesforceFields },
  };

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasImage = image?.url;

  const typeToUse = type || 'Contact';

  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="ContactForm">
      <div className={styles.imageContainer}>{hasImage && <Image className={styles.image} image={image} />}</div>
      <div className={styles.form}>
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
        {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
        <Form
          className={styles.contactForm}
          fields={formFields[typeToUse].fields}
          tag={formFields[typeToUse].tag}
          list={formFields[typeToUse].list}
          type={type}
          successMessage="Thanks for getting in touch!"
        />
      </div>
    </Section>
  );
};

export default ContactForm;
