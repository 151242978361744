// extracted by mini-css-extract-plugin
export var container = "styles-module--container--c65ae";
export var contentContainer = "styles-module--contentContainer--374e4";
export var item = "styles-module--item--1f9f3";
export var itemAuthor = "styles-module--itemAuthor--412c1";
export var itemAvatarContainer = "styles-module--itemAvatarContainer--6491a";
export var itemCaption = "styles-module--itemCaption--d28f2";
export var itemContent = "styles-module--itemContent--4ac0c";
export var itemImageContainer = "styles-module--itemImageContainer--25cbe";
export var itemName = "styles-module--itemName--ef93f";
export var itemNameContainer = "styles-module--itemNameContainer--c4324";
export var itemQuote = "styles-module--itemQuote--2a993";
export var itemQuoteContainer = "styles-module--itemQuoteContainer--f2f6e";
export var itemRole = "styles-module--itemRole--24913";
export var lightText = "styles-module--lightText--2c9e9";
export var noImage = "styles-module--noImage--ce230";
export var quotesIcon = "styles-module--quotesIcon--f76e7";
export var quotesIconEnd = "styles-module--quotesIconEnd--d7d60";
export var section = "styles-module--section--b4d89";
export var slickActive = "styles-module--slick-active--1c9b6";
export var title = "styles-module--title--97c09";