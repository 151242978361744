import React from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const Wysiwyg = (props) => {
  const { data } = props;
  const { primary, id } = data;
  const { content, spacing, spacing_override: spacingOverride } = primary;

  // content check
  const wysiwygHtml = content?.html;

  return (
    <Section className={styles.wysiwyg} spacing={spacing} spacingOverride={spacingOverride} id={id}>
      {wysiwygHtml && (
        <div
          className={styles.wysiwygContent}
          dangerouslySetInnerHTML={{
            __html: wysiwygHtml,
          }}
        />
      )}
    </Section>
  );
};

export default Wysiwyg;
