// extracted by mini-css-extract-plugin
export var activeDot = "styles-module--activeDot--dbd31";
export var container = "styles-module--container--1eb26";
export var content = "styles-module--content--c7674";
export var featureScrollSlide = "styles-module--featureScrollSlide--bc193";
export var grid = "styles-module--grid--d6380";
export var image = "styles-module--image--a1e72";
export var section = "styles-module--section--924d7";
export var sideBarDot = "styles-module--sideBarDot--6977a";
export var sidebar = "styles-module--sidebar--06783";
export var stickyImage = "styles-module--stickyImage--0d2b3";
export var textContainer = "styles-module--textContainer--ae8d9";
export var title = "styles-module--title--82a10";
export var visibleImage = "styles-module--visibleImage--8aec8";