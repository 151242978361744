// extracted by mini-css-extract-plugin
export var blackBg = "styles-module--blackBg--3b8bb";
export var card = "styles-module--card--8e3fa";
export var cardContent = "styles-module--cardContent--ec4bc";
export var cardTitle = "styles-module--cardTitle--4a494";
export var container = "styles-module--container--f8749";
export var greyBg = "styles-module--greyBg--ec912";
export var grid = "styles-module--grid--d4665";
export var modal = "styles-module--modal--faea1";
export var modalContainer = "styles-module--modalContainer--c6d1b";
export var modalContent = "styles-module--modalContent--0ae34";
export var modalImage = "styles-module--modalImage--4a4d4";
export var modalTitle = "styles-module--modalTitle--3aa8c";
export var readMore = "styles-module--readMore--038d2";
export var section = "styles-module--section--9c0a9";
export var textContainer = "styles-module--textContainer--b3c58";
export var title = "styles-module--title--f9910";
export var whiteBg = "styles-module--whiteBg--fe5df";