import React from 'react';
import { Section, Link, Image, Button, RichText } from 'components';
import { ReactComponent as Logo } from 'images/logo.svg';

import * as styles from './styles.module.scss';

const SimpleHeader = (props) => {
  const { data } = props;
  const { primary, id } = data;
  const {
    image,
    include_logo: includeLogo,
    tagline,
    title,
    content,
    cta_text: ctaText,
    cta_link: ctaLink,
    spacing,
    spacing_override: spacingOverride,
  } = primary;

  const titleHtml = title?.html;
  const hasTitleHtml = titleHtml.length > 8;
  const taglineHtml = tagline?.html;
  const hasTaglineHtml = taglineHtml?.length > 8;
  const contentHtml = content?.html;
  const hasContentHtml = hasTaglineHtml?.length > 8;
  const hasImage = image?.url;
  const hasCta = ctaLink?.url && ctaText?.text;

  return (
    <Section
      containerClassName={styles.container}
      sliceName="SimpleHeader"
      className={`inventia-header ${styles.section}`}
      spacing={spacing}
      spacingOverride={spacingOverride}
      id={id}
    >
      <div className={styles.grid}>
        <div className={styles.textContainer}>
          {includeLogo && <Logo className={styles.logo} />}
          {hasTaglineHtml && <div dangerouslySetInnerHTML={{ __html: taglineHtml }} className={styles.tagline} />}
          {hasTitleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
          {hasContentHtml && <RichText html={contentHtml} className={styles.content} />}
          {hasCta && (
            <Button to={ctaLink.url} className={styles.button}>
              {ctaText.text}
            </Button>
          )}
        </div>
        {hasImage && <Image image={image} className={styles.image} />}
      </div>
      {/* <CircleWithLine pulse className={styles.circleWithLine} /> */}
    </Section>
  );
};

export default SimpleHeader;
