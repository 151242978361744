import React, { useState, useEffect } from 'react';
import { PostCard, Link } from 'components';
import * as styles from './styles.module.scss';

const PostGrid = ({
  posts = [],
  className,
  title,
  categoryLink,
  categoryLinkText,
  vertical,
  darkMode,
  showExcerpt,
  readMore,
}) => {
  // pagination logic
  const paginationAmount = 9;
  const [numOfPosts, setNumOfPost] = useState(paginationAmount);
  const [reachedEnd, setReachedEnd] = useState(false);
  // check if pagination required on mount
  useEffect(() => {
    if (numOfPosts >= posts?.length) {
      setReachedEnd(true);
    }
  }, []);
  // button pagination
  const onLoadMore = (event) => {
    event.preventDefault();
    setNumOfPost(numOfPosts + paginationAmount);
    if (numOfPosts + paginationAmount >= posts.length) {
      setReachedEnd(true);
    }
  };

  return (
    <div className={`${darkMode ? styles.darkmode : ''} ${vertical ? styles.vertical : ''} ${className || ''}`}>
      {title && (
        <div className={styles.titleContainer}>
          <div>
            <span className={styles.title}>{title}</span>
          </div>
          {categoryLink && (
            <Link to={categoryLink} className={styles.cta}>
              {categoryLinkText || 'View all'}
            </Link>
          )}
        </div>
      )}
      <div className={` ${vertical ? styles.verticalVariant : ''} ${styles.postsContainer}`}>
        {/* IF VERTICAL, PAGINATE - ELSE, SHOW ALL POSTS */}
        {vertical
          ? posts.slice(0, numOfPosts).map((post) => {
            const { uid } = post;
            return (
              <PostCard
                key={uid}
                post={post}
                vertical={vertical}
                darkMode={darkMode}
                showExcerpt={showExcerpt}
                readMore={readMore}
              />
            );
          })
          : posts.map((post) => {
            const { uid } = post;
            return (
              <PostCard
                key={uid}
                post={post}
                vertical={vertical}
                darkMode={darkMode}
                showExcerpt={showExcerpt}
                readMore={readMore}
              />
            );
          })}
      </div>
      {posts?.length > 3 && !reachedEnd && vertical && (
        <Link to="#more" type="button" onClick={onLoadMore} className={`button primary ${styles.button}`}>
          LOAD MORE
        </Link>
      )}
    </div>
  );
};

export default PostGrid;
