import React, { useState, Fragment } from 'react';
import { Section, Link, OnVisible, Image } from 'components';
import * as styles from './styles.module.scss';

const VeritalCarousel = (props) => {
  const { data } = props;
  const { items } = data;

  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlide = (isVisible, index) => {
    if (isVisible) {
      setActiveIndex(index);
    }
  };

  const activeImage = items[activeIndex]?.image;

  return (
    <Section containerClassName={styles.container} sliceName="VeritalCarousel" className={styles.section}>
      <div className={styles.grid}>
        {/* SIDEBAR */}
        <div className={styles.sidebar}>
          {items.map((item, index) => (
            <div
              key={`dotPoint ${index}`}
              className={` ${activeIndex === index ? styles.activeDot : ''} ${styles.sideBarDot}`}
            />
          ))}
        </div>
        {/* // sticky image */}
        <OnVisible
          threshold={0.1}
          triggerOnce={false}
          className={styles.stickyImage}
          visibleClassName={styles.visibleImage}
        >
          <Image className={styles.image} image={activeImage} />
        </OnVisible>
        {/* // scrolling text */}
        <div className={styles.scrollContent}>
          {items.map((item, index) => {
            const { content, cta_link: ctaLink, cta_text: ctaText, title } = item;
            return (
              <Fragment key={title.text}>
                <OnVisible
                  className={styles.featureScrollSlide}
                  onChange={(isVisible) => handleSlide(isVisible, index)}
                  triggerOnce={false}
                  visibleClassName={styles.visibleSlide}
                  threshold={0.75}
                >
                  <div className={styles.textContainer}>
                    {title?.html && <div dangerouslySetInnerHTML={{ __html: title.html }} className={styles.title} />}
                    {content?.html && (
                      <div dangerouslySetInnerHTML={{ __html: content?.html }} className={styles.content} />
                    )}
                    {ctaLink?.url && ctaText?.text && (
                      <Link className={`cta ${styles.button}`} to={ctaLink.url}>
                        {ctaText.text}
                      </Link>
                    )}
                  </div>
                </OnVisible>
              </Fragment>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default VeritalCarousel;
