// extracted by mini-css-extract-plugin
export var blackBg = "styles-module--blackBg--5515c";
export var button = "styles-module--button--422f1";
export var content = "styles-module--content--4b10e";
export var greyBg = "styles-module--greyBg--2c3a3";
export var halfBg = "styles-module--halfBg--84d1d";
export var image = "styles-module--image--0e561";
export var imageContainer = "styles-module--imageContainer--4d977";
export var logo = "styles-module--logo--8c7eb";
export var logosContainer = "styles-module--logosContainer--ff972";
export var section = "styles-module--section--7d32f";
export var tagline = "styles-module--tagline--eacf6";
export var title = "styles-module--title--c0290";
export var whiteBg = "styles-module--whiteBg--bc348";