// extracted by mini-css-extract-plugin
export var award = "styles-module--award--07aab";
export var awardContainer = "styles-module--awardContainer--861f6";
export var carousel = "styles-module--carousel--e5b01";
export var carouselVariant = "styles-module--carouselVariant--36c47";
export var company = "styles-module--company--25d73";
export var container = "styles-module--container--b4d3f";
export var content = "styles-module--content--78cc9";
export var grid = "styles-module--grid--122c3";
export var image = "styles-module--image--06ad8";
export var imageContainer = "styles-module--imageContainer--760b5";
export var section = "styles-module--section--e376c";
export var textContainer = "styles-module--textContainer--ac36b";
export var title = "styles-module--title--7fbeb";