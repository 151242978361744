import React from 'react';
import { Section, Link, Image } from 'components';
import * as styles from './styles.module.scss';

const TaglineTitleLogosImage = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { image, tagline, title, background, content, cta_link: ctaLink, cta_text: ctaText } = primary;

  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;
  const hasImage = image?.url;
  const contentHtml = content?.html;
  const hasCta = ctaLink?.url && ctaText?.text;

  const backgrounds = {
    Black: styles.blackBg,
    White: styles.whiteBg,
    Grey: styles.greyBg,
  };

  return (
    <Section
      containerClassName={styles.container}
      className={`${backgrounds[background] || styles.halfBg} ${styles.section}`}
      sliceName="TaglineTitleLogosImage"
    >
      {tagline?.text && <div dangerouslySetInnerHTML={{ __html: taglineHtml }} className={styles.tagline} />}
      {title?.text && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      <div className={styles.logosContainer}>
        {items.map((item) => {
          const { logo } = item;
          return <Image className={styles.logo} image={logo} objectFit="contain" key={logo.url} />;
        })}
      </div>
      {hasImage && (
        <div className={styles.imageContainer}>
          <Image className={styles.image} image={image} />
        </div>
      )}
      {content?.text && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
      {hasCta && (
        <Link className={`button primary ${styles.button}`} to={ctaLink.url}>
          {ctaText.text}
        </Link>
      )}
    </Section>
  );
};

export default TaglineTitleLogosImage;
