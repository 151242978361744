import { useState, useEffect } from 'react';
import lunr from 'lunr';

const useBlogSearchIndex = ({ allPrismicResourcePost }) => {
  const [loading, setLoading] = useState(false);
  const [blogIndex, setBlogIndex] = useState(null);

  const { nodes = [] } = allPrismicResourcePost;

  useEffect(() => {
    if (!blogIndex && nodes.length) {
      setLoading(true);

      const builder = new lunr.Builder();
      builder.ref('uid');
      builder.field('title', { boost: 10 });
      builder.field('excerpt', { boost: 7 });
      builder.field('author', { boost: 5 });
      builder.field('category', { boost: 3 });

      nodes.forEach((node) => {
        builder.add({
          uid: node?.uid,
          title: node?.data?.title?.text,
          author: node?.data?.author?.document?.data?.author_name?.text,
          category: node?.data?.category?.document?.data?.category_name?.text,
          excerpt: node?.data?.excerpt?.text,
        });
      });

      const index = builder.build();
      setLoading(false);
      setBlogIndex(index);
    }
  }, [nodes]);

  return {
    blogIndexLoading: loading,
    blogIndex,
  };
};

export default useBlogSearchIndex;
